import {
  Box,
  FormControlLabel,
  styled,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useLoadData from 'src/hook/useLoadData';
import { handleMutation } from 'src/utils/api.utils';
import StyledSwitch from 'src/components/forms/StyledSwitch';
import { CustomDataGrid } from '../../../components/DataGrid';
import SearchComponent from '../../../components/Search';
import useLoadDataList from '../../../hook/useLoadDataList';
import {
  getAllUsers,
  getIsGlobalMaintenanceMode,
  ReducedUser,
  updateIsGlobalMaintenanceMode,
  UserStatus,
} from '../../../services/user';
import { searchBarMatch } from '../../../utils/filter.utils';
import AdminPageWithTabs from '../components/AdminPageWithTabs';

const tabs = ['all', 'pending', 'headOffice', 'agency', 'disabled'] as const;

type Tab = (typeof tabs)[number];

type DataRow = Omit<ReducedUser, 'company'> & {
  reason: string;
  headOfficeReason: string;
  name: string;
  status: UserStatus;
  countQuotes: number;
  countOrders: number;
  totalIncome: number;
};

const StyledCell = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const columns: GridColDef<DataRow>[] = [
  {
    field: 'reason',
    headerName: 'Nom du siège',
    flex: 1,
    valueGetter: (params: GridValueGetterParams<string, DataRow>) =>
      params.row.role === 'AGENCY' ? params.row.headOfficeReason : params.value,
  },
  {
    field: 'name',
    headerName: "Nom de l'agence",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string, DataRow>) =>
      params.row.role === 'AGENCY' ? (
        <StyledCell>
          <Typography> {params.row.reason}</Typography>
          <Typography variant="caption" textAlign="right">
            {params.value}
          </Typography>
        </StyledCell>
      ) : (
        '-'
      ),
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'lastName',
    headerName: 'Nom',
    flex: 1,
  },
  {
    field: 'firstName',
    headerName: 'Prénom',
    flex: 1,
  },
  {
    field: 'countQuotes',
    headerName: 'Nombres de devis',
    flex: 1,
  },
  {
    field: 'countOrders',
    headerName: 'Nombre de commandes',
    flex: 1,
  },
  {
    field: 'totalIncome',
    headerName: 'Total H.T',
    flex: 1,
    valueGetter: (params: GridValueGetterParams<number>) =>
      new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }).format(params.value ?? 0),
  },
];

// TODO add stats
const formatDataRow = (row: ReducedUser): DataRow => {
  const { company, ...rest } = row;
  return {
    ...rest,
    reason: company?.reason ?? '-',
    headOfficeReason: company?.headOffice?.headOffice.company.reason ?? '-',
    name: company?.name ?? '-',
  };
};

const filterRow = (tab: Tab, row: DataRow, search: string) => {
  if (!searchBarMatch(search, [row.email, row.firstName, row.lastName, row.name, row.reason]))
    return false;
  switch (tab) {
    case 'agency': {
      return row.role === 'AGENCY' && row.status !== 'DISABLED';
    }
    case 'headOffice': {
      return row.role === 'HEAD_OFFICE' && row.status !== 'DISABLED';
    }
    case 'pending': {
      return row.status === 'PENDING';
    }
    case 'disabled': {
      return row.status === 'DISABLED';
    }
    default: {
      return row.status !== 'DISABLED';
    }
  }
};

const Users = () => {
  const navigate = useNavigate();
  const { data } = useLoadDataList(getAllUsers);

  const [dataRows, setDataRows] = useState<DataRow[]>([]);

  const {
    data: isOnMaintenance,
    onRefresh,
    refreshing,
  } = useLoadData(() => getIsGlobalMaintenanceMode());

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const handleChangeIsGlobalMaintenanceMode = useCallback(() => {
    setOpenConfirmModal(true);
  }, []);

  const handleConfirmMaintenance = useCallback(() => {
    handleMutation(
      (datas) => updateIsGlobalMaintenanceMode(datas),
      {
        isMaintenanceMode: !isOnMaintenance,
      },
      () => {
        onRefresh();
        setOpenConfirmModal(false);
      },
    );
  }, [isOnMaintenance, onRefresh]);

  const [search, setSearch] = useState('');
  useEffect(
    () =>
      setDataRows(
        data
          .map((row) => formatDataRow(row))
          .sort(
            (a, b) =>
              a.headOfficeReason.localeCompare(b.headOfficeReason) || a.name.localeCompare(b.name),
          ),
      ),
    [data],
  );

  const tabToComponent = useCallback(
    (tab: Tab) => {
      return (
        <>
          <SearchComponent
            search={search}
            setSearch={setSearch}
            sx={{ position: 'absolute', right: 0, top: '-70px', background: 'white' }}
          />
          <CustomDataGrid
            rows={dataRows.filter((row) => filterRow(tab, row, search))}
            columns={columns}
            onRowClick={(params: GridRowParams<DataRow>) => navigate(params.row.id)}
            sx={{ cursor: 'pointer' }}
          />
          <FormControlLabel
            control={
              <StyledSwitch
                checked={isOnMaintenance ?? false}
                onChange={handleChangeIsGlobalMaintenanceMode}
                disabled={refreshing}
              />
            }
            label="Activer le mode maintenance globale"
            sx={{
              border: '1px solid #F2F4F7',
              borderRadius: '10px',
              height: '56px',
              width: '100%',
              margin: 0,
            }}
          />
          <Dialog open={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
            <DialogContent>
              <Typography>
                Êtes-vous sûr de vouloir {isOnMaintenance ? 'désactiver' : 'activer'} le mode
                maintenance globale ?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenConfirmModal(false)}>Annuler</Button>
              <Button onClick={handleConfirmMaintenance} variant="contained" color="primary">
                Confirmer
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    },
    [
      search,
      handleChangeIsGlobalMaintenanceMode,
      refreshing,
      dataRows,
      navigate,
      isOnMaintenance,
      openConfirmModal,
      handleConfirmMaintenance,
    ],
  );

  return (
    <AdminPageWithTabs
      tabs={tabs}
      pageName="Utilisateurs"
      tabToComponent={tabToComponent}
      tabsSx={{ maxWidth: '75%' }}
    />
  );
};

export default Users;
