/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Box, styled, Switch, SwitchProps, Typography } from '@mui/material';
import { useState } from 'react';
import { PieceDetail } from 'src/hook/useConfigurator';
import { useReacpPrice } from 'src/hook/useRecapPrice';
import { ModalType } from 'src/Screens/Configurator';
import { Assembly } from 'src/services/assemblies';
import SearchIcon from '@mui/icons-material/Search';
import { getColorName, getFontName, traductAlign, truncateText } from 'src/Screens/project/Recap';
import { useUser } from 'src/context/UserProvider';

type TexturesButtonsProps = {
  assembly: Assembly;
  setModal: (modalType: ModalType) => void;
  submit: ({
    detailed,
    discount,
    hasToBeOrdered,
    installations,
  }: {
    detailed?: boolean;
    discount?: number;
    hasToBeOrdered?: boolean;
    installations?: { price: number; name: string }[];
  }) => void;
  getAssemblyDetails: () => {
    pieces: PieceDetail[];
    accesories: PieceDetail[];
    steles: PieceDetail[];
    platings: PieceDetail[];
    patterns: {
      scale: {
        x: number;
        y: number;
      };
    }[];
    engravings: any[];
    underAssemblies: {
      pieces: PieceDetail[];
      underAssemblyName: string;
      underAssemblyUUID: string;
      x: number;
      y: number;
      z: number;
      size: number;
    }[];
  };
  getAssemblySize: (wihoutSole?: boolean) => { depth: number; width: number };
  isLoading: boolean;
  activePieces: any[];
  platings: any[];
  steles: any[];
  activeInstallations: { price: number; name: string; display: boolean }[];
  setActiveInstallations: (
    activeInstallations: { price: number; name: string; display: boolean }[],
  ) => void;
};

export const PriceRecap = ({
  assembly,
  setModal,
  submit,
  getAssemblyDetails,
  getAssemblySize,
  isLoading,
  activePieces,
  platings,
  steles,
  activeInstallations,
  setActiveInstallations,
}: TexturesButtonsProps) => {
  const { user } = useUser();
  const [isPriceRecapOpen, setIsPriceRecapOpen] = useState(false);

  const {
    simplesPieces,
    assemblyDetails,
    showPurchasePrice,
    detailedQuote,
    priceFormatter,
    additionalServicesPrice,
    delivery,
    finalPrice,
    getFormatedPrice,
    getMonumentPrice,
    setOpenUnderAssemblies,
    openUnderAssemblies,
    detailKeys,
    getAccessoriesPrice,
    setOpenedPattern,
    openedPattern,
    discount,
  } = useReacpPrice({
    assembly,
    getAssemblyDetails,
    getAssemblySize,
    activePieces,
    platings,
    steles,
    activeInstallations,
    setActiveInstallations,
  });

  return (
    <Container
      style={{
        width: '55vw',
      }}
    >
      {isPriceRecapOpen ? (
        <QuoteContainer>
          <QuoteHeader>
            <SearchIcon />
            Devis vente
          </QuoteHeader>
          <QuoteBody>
            <RecapWrapper>
              {(simplesPieces.pieces && simplesPieces.pieces.length > 0) ||
              (simplesPieces.platings && simplesPieces.platings.length > 0) ||
              (simplesPieces.steles && simplesPieces.steles.length > 0) ||
              (assemblyDetails?.underAssemblies && assemblyDetails?.underAssemblies.length > 0) ? (
                <>
                  <RecapHeader>
                    <Typography variant="inherit">Monument</Typography>
                    {detailedQuote ? (
                      <Typography variant="inherit">{getMonumentPrice()}</Typography>
                    ) : null}
                  </RecapHeader>

                  {assemblyDetails?.underAssemblies.map((underAssembly, index) => {
                    return (
                      <>
                        <RecapItem
                          style={{ cursor: 'pointer', gap: 4, alignItems: 'center' }}
                          onClick={() => {
                            setOpenUnderAssemblies(
                              openUnderAssemblies.map((el, elIndex) => {
                                if (elIndex === index) return !el;
                                return el;
                              }),
                            );
                          }}
                        >
                          {openUnderAssemblies[index] ? <ExpandMore /> : <ChevronRight />}
                          <Typography
                            variant="inherit"
                            textTransform="uppercase"
                            width="60%"
                            style={{ fontSize: 14 }}
                          >
                            {underAssembly.underAssemblyName}
                          </Typography>
                          <Typography variant="inherit" style={{ fontSize: 14 }}>
                            {Math.floor(underAssembly.z * 100)} x{' '}
                            {Math.floor(underAssembly.x * 100)} x{' '}
                            {Math.floor(underAssembly.y * 100)}
                            ht
                          </Typography>
                        </RecapItem>

                        {openUnderAssemblies[index]
                          ? underAssembly.pieces.map((piece) => {
                              return (
                                <RecapItem key={`${piece.name}_${index}`} style={{ padding: 0 }}>
                                  <Row style={{ width: '55%', marginLeft: '10%' }}>
                                    <Typography
                                      variant="inherit"
                                      textTransform="uppercase"
                                      style={{ fontSize: 12 }}
                                    >
                                      {piece.name}
                                    </Typography>
                                    -
                                    <Typography
                                      variant="inherit"
                                      textTransform="uppercase"
                                      style={{ fontSize: 12 }}
                                    >
                                      {piece.granit?.name}
                                    </Typography>
                                  </Row>
                                  <Typography variant="inherit" style={{ fontSize: 12 }}>
                                    {Math.round(piece.z * 100)} x {Math.round(piece.x * 100)} x{' '}
                                    {Math.round(piece.y * 100)}
                                    ht
                                  </Typography>
                                </RecapItem>
                              );
                            })
                          : null}
                      </>
                    );
                  })}
                  {detailKeys.map((key) =>
                    simplesPieces[key]?.map((el: PieceDetail, index: number) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <RecapItem key={`${el.name}_${index}`} style={{ fontSize: 14 }}>
                          <Row style={{ width: '64%' }}>
                            <Typography variant="inherit" textTransform="uppercase">
                              {el.name}
                            </Typography>
                            -
                            <Typography variant="inherit" textTransform="uppercase">
                              {el.granit?.name}
                            </Typography>
                          </Row>
                          <Typography variant="inherit" style={{ fontSize: 14 }}>
                            {Math.round(el.z * 100)} x {Math.round(el.x * 100)} x{' '}
                            {Math.round(el.y * 100)}
                            ht
                          </Typography>
                        </RecapItem>
                      );
                    }),
                  )}
                </>
              ) : null}

              {assemblyDetails?.accesories && assemblyDetails?.accesories.length > 0 ? (
                <>
                  <RecapHeader>
                    <Typography variant="inherit">Accessoires</Typography>
                    {detailedQuote ? (
                      <Typography variant="inherit">{getAccessoriesPrice()}</Typography>
                    ) : null}
                  </RecapHeader>

                  {assemblyDetails?.accesories.map((accesory: any, index: number) => {
                    return (
                      <RecapItem key={`accesory_${index}`} style={{ fontSize: 14 }}>
                        <Row style={{ width: '64%' }}>
                          <Typography variant="inherit" textTransform="uppercase">
                            {accesory.name} - {accesory.granit?.name}
                          </Typography>
                        </Row>

                        <Typography variant="inherit" style={{ fontSize: 14 }}>
                          {Math.round(accesory.z * 100)} x {Math.round(accesory.x * 100)} x{' '}
                          {Math.round(accesory.y * 100)}
                          ht
                        </Typography>
                      </RecapItem>
                    );
                  })}
                </>
              ) : null}

              {assemblyDetails?.patterns && assemblyDetails?.patterns.length > 0 ? (
                <>
                  <RecapHeader>
                    <Typography variant="inherit">Motifs</Typography>
                    {detailedQuote ? (
                      <Typography variant="inherit">
                        {assemblyDetails?.patterns
                          .reduce(
                            (total, obj) =>
                              (total as number) +
                              (showPurchasePrice
                                ? (obj.htPrice as number)
                                : (obj.ttcPrice as number)),
                            0,
                          )
                          .toFixed(2)}{' '}
                        €
                      </Typography>
                    ) : null}
                  </RecapHeader>

                  {assemblyDetails?.patterns.map((pattern: any, index: number) => {
                    return (
                      <>
                        <RecapItem
                          key={`pattern_${index}`}
                          style={{ fontSize: 14, cursor: 'pointer' }}
                          onClick={() =>
                            setOpenedPattern(
                              openedPattern === pattern.uuid ? undefined : pattern.uuid,
                            )
                          }
                        >
                          {openedPattern === pattern.uuid ? <ExpandMore /> : <ChevronRight />}
                          <Typography
                            variant="inherit"
                            textTransform="uppercase"
                            width="55%"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {pattern.name}
                          </Typography>
                          <Typography variant="inherit" style={{ fontSize: 14 }}>
                            {pattern.scale.x} x {pattern.scale.y}
                            ht
                          </Typography>
                        </RecapItem>
                        {openedPattern === pattern.uuid ? (
                          <RecapItem
                            style={{ fontSize: 12, paddingLeft: 32 }}
                            onClick={() => setOpenedPattern(pattern.uuid)}
                          >
                            <Typography variant="inherit" textTransform="uppercase" width="100%">
                              {pattern.description}
                            </Typography>
                          </RecapItem>
                        ) : null}
                      </>
                    );
                  })}
                </>
              ) : null}

              {assemblyDetails?.engravings && assemblyDetails?.engravings.length > 0 ? (
                <>
                  <RecapHeader>
                    <Typography variant="inherit">Gravure</Typography>
                    {detailedQuote ? (
                      <Typography variant="inherit">
                        {detailedQuote ? (
                          <Typography variant="inherit">
                            {assemblyDetails?.engravings
                              .reduce(
                                (total, obj) =>
                                  (total as number) +
                                  (showPurchasePrice
                                    ? (obj.htPrice as number)
                                    : (obj.ttcPrice as number)),
                                0,
                              )
                              .toFixed(2)}{' '}
                            €
                          </Typography>
                        ) : null}
                      </Typography>
                    ) : null}
                  </RecapHeader>

                  {assemblyDetails?.engravings.map((engraving: any, index: number) => {
                    return (
                      <>
                        <RecapItem
                          key={`engraving_${index}`}
                          style={{ fontSize: 14, cursor: 'pointer' }}
                          onClick={() =>
                            setOpenedPattern(
                              openedPattern === engraving.uuid ? undefined : engraving.uuid,
                            )
                          }
                        >
                          {openedPattern === engraving.uuid ? <ExpandMore /> : <ChevronRight />}
                          <Typography
                            variant="inherit"
                            textTransform="uppercase"
                            width="55%"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {truncateText(engraving.text, 15)}
                          </Typography>
                          <Typography variant="inherit" style={{ fontSize: 14 }}>
                            {engraving.fontSize}
                            cm
                          </Typography>
                        </RecapItem>
                        {openedPattern === engraving.uuid ? (
                          <RecapItem
                            style={{ fontSize: 12, paddingLeft: 32 }}
                            onClick={() => setOpenedPattern(engraving.uuid)}
                          >
                            <div>
                              <Typography variant="inherit" width="100%">
                                Couleur: {getColorName(engraving.color)}
                              </Typography>
                              <Typography variant="inherit" width="100%">
                                Police: {getFontName(engraving.fontFamily)}
                              </Typography>
                              <Typography variant="inherit" width="100%">
                                Espacement: {engraving.letterSpacing} cm
                              </Typography>
                              <Typography variant="inherit" width="100%">
                                Alignement: {traductAlign(engraving.align)}
                              </Typography>
                            </div>
                          </RecapItem>
                        ) : null}
                      </>
                    );
                  })}
                </>
              ) : null}

              {showPurchasePrice
                ? null
                : activeInstallations.map((installation) => {
                    if (!installation.display) return null;
                    return (
                      <RecapHeader sx={{ marginBottom: '22px' }}>
                        <Typography variant="inherit">{installation.name}</Typography>
                        {detailedQuote ? (
                          <Typography variant="inherit">
                            {priceFormatter.format(installation.price)}
                          </Typography>
                        ) : null}
                      </RecapHeader>
                    );
                  })}

              {!showPurchasePrice && additionalServicesPrice ? (
                <>
                  <RecapHeader>
                    <Typography variant="inherit">Travaux supplémentaires</Typography>
                    {detailedQuote ? (
                      <Typography variant="inherit">
                        {priceFormatter.format(additionalServicesPrice)}
                      </Typography>
                    ) : null}
                  </RecapHeader>
                  {assembly.quote?.additionalServicesOnQuote?.map(
                    (additionalServicesOnQuote: any) => {
                      return (
                        <RecapItem>
                          <Typography
                            variant="inherit"
                            textTransform="uppercase"
                            sx={{ fontSize: '14px' }}
                          >
                            {additionalServicesOnQuote?.additionalService.name}
                          </Typography>
                        </RecapItem>
                      );
                    },
                  )}
                </>
              ) : null}

              {(assembly?.quote?.user?.quoteSettings?.displayDelivery || showPurchasePrice) &&
              !assembly?.quote?.user?.quoteSettings?.postageDue ? (
                <RecapHeader>
                  <Typography variant="inherit">Livraison</Typography>
                  {detailedQuote ? (
                    <Typography variant="inherit">{priceFormatter.format(delivery)}</Typography>
                  ) : null}
                </RecapHeader>
              ) : null}
            </RecapWrapper>
            {Number.parseFloat(discount ?? '') > 0 || discount === '' ? (
              <Footer style={{ padding: '10px', paddingBottom: '0px' }}>
                <Typography sx={{ color: '#98A2B3' }} align="right">
                  Remise
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 24 }}>
                  <Typography sx={{ color: '#98A2B3' }} align="right">
                    {Number.parseFloat(discount ?? '').toFixed(2)} €
                  </Typography>
                </div>
              </Footer>
            ) : null}
          </QuoteBody>
        </QuoteContainer>
      ) : null}
      <ButtonContainer>
        <IOSSwitch
          checked={isPriceRecapOpen}
          onChange={() => setIsPriceRecapOpen(!isPriceRecapOpen)}
        />
        <Col>
          <Typography
            style={{
              fontSize: '16px',
              color: '#000',
              fontWeight: 600,
            }}
          >
            {priceFormatter.format(getFormatedPrice(finalPrice))} TTC
          </Typography>
          <Typography
            style={{
              fontSize: '12px',
              color: '#98A2B3',
            }}
          >
            Devis en temps réel
          </Typography>
        </Col>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  height: '100%',
  padding: '20px',
  pointerEvents: 'none',
  bottom: 0,
  left: 0,
  zIndex: 150,
  gap: 24,
});

const ButtonContainer = styled('div')({
  height: '55px',
  backgroundColor: 'white',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  padding: 12,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  pointerEvents: 'auto',
  gap: 12,
  width: 'fit-content',
});

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#BC9A67' : '#BC9A67',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#BC9A67',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const Col = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const QuoteContainer = styled('div')({
  backgroundColor: 'white',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  width: 'fit-content',
  pointerEvents: 'auto',
});

const QuoteBody = styled('div')({
  padding: 12,
  overflow: 'auto',
  maxHeight: '60vh',
  height: 'fit-content',
});

const QuoteHeader = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 4,
  padding: 12,
  backgroundColor: '#F6F0E7',
  color: '#BC9A67',
  fontWeight: 600,
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
});

const RecapWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: 1,
});

const RecapHeader = styled(Box)({
  padding: '10px 20px',
  background: '#F6F0E7',
  display: 'flex',
  borderRadius: '8px',
  justifyContent: 'space-between',
  width: '100%',
  fontWeight: 700,
  fontSize: '20px',
});

const RecapItem = styled(Box)({
  padding: '10px 20px',
  display: 'flex',
  borderRadius: '8px',
  alignSelf: 'flex-start',
  marginLeft: '0%',
  width: '100%',
  fontWeight: 600,
  fontSize: '16px',
  color: '#475467',
  gap: '20px',
});

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

const Footer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 40px 32px',
  background: '#F9FAFB',
  width: '100%',
  position: 'relative',
});
