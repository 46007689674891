import { styled, Switch, SwitchProps, Typography } from '@mui/material';
import { StateNames, States } from 'src/Screens/Configurator';

interface AdvancedModeProps {
  getValue: <T extends StateNames>(stateName: T) => States[T];
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
}

export const AdvancedMode = ({ getValue, setValue }: AdvancedModeProps) => {
  return (
    <Row
      style={{
        alignItems: 'center',
        gap: '6px',
        marginBottom: '6px',
        width: '100%',
        justifyContent: 'flex-start',
      }}
    >
      <Typography variant="body2" color="#344054" style={{ fontWeight: 400, fontSize: '12px' }}>
        Mode avancé
      </Typography>
      <IOSSwitch
        checked={getValue('hasAdvancedMode')}
        onChange={() => setValue('hasAdvancedMode', !getValue('hasAdvancedMode'))}
      />
    </Row>
  );
};

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#BC9A67' : '#BC9A67',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#BC9A67',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
