/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Decimal from 'decimal.js';
import { CustomSteleType } from 'src/components/3d/Interface/Customization/Dimension/CustomSteles';
import { PieceDetail } from 'src/hook/useConfigurator';
import { getColorName, getFontName } from 'src/Screens/project/Recap';
import { Position } from 'src/services/assemblies';
import { Euler, Vector3 } from 'three';
import * as THREE from 'three';

export const compare2Float32Array = (a: Float32Array, b: Float32Array) => {
  return a[0] === b[0] && a[1] === b[1] && a[2] === b[2];
};

export const compare2Point = (
  a: { x: number; z: number; y: number },
  b: { x: number; z: number; y: number },
) => {
  return a.x === b.x && a.y === b.y && a.z === b.z;
};

export const float32ToPoint = (a: Float32Array) => {
  return {
    x: a[0],
    y: a[1],
    z: a[2],
  };
};
export const pointToFloat32 = (a: { x: number; y: number; z: number }) => {
  return new Float32Array([a.x, a.y, a.z]);
};

export const getIndexOfFloat32Array = (array: Float32Array[], item: Float32Array) => {
  let i = -1;

  for (const [index, el] of array.entries()) {
    if (compare2Float32Array(el, item)) {
      i = index;
    }
  }
  return i;
};

export const getPointInHistory = (
  history: { [key: string]: Float32Array },
  point: Float32Array,
) => {
  const getIsTheSameNumber = (a: number, b: number) => {
    if (a - b < 0.0001 && a - b > -0.0001) {
      return true;
    }
    return false;
  };

  for (const key in history) {
    const splitedPoint = key.split(',');

    if (splitedPoint && splitedPoint.length === 3) {
      const x = Number.parseFloat(splitedPoint[0]);
      const y = Number.parseFloat(splitedPoint[1]);
      const z = Number.parseFloat(splitedPoint[2]);

      if (
        getIsTheSameNumber(x, point[0]) &&
        getIsTheSameNumber(y, point[1]) &&
        getIsTheSameNumber(z, point[2])
      ) {
        return history[key];
      }
    }
  }
};

export const getModificationWithHistory = (
  history: { [key: string]: Float32Array },
  point: {
    x: number;
    y: number;
    z: number;
  },
) => {
  const floatPoint = pointToFloat32(point);
  const pointInHistoy = getPointInHistory(history, floatPoint);

  if (pointInHistoy) {
    return {
      x: pointInHistoy[0],
      y: pointInHistoy[1],
      z: pointInHistoy[2],
    };
  }
  return { x: point.x, z: point.z, y: point.y };
};

export const setVert = (geometry: any, vertIndex: any, pos: any) => {
  pos = pos || {};
  const position = geometry.getAttribute('position');
  position.array[vertIndex] = pos.x === undefined ? position.array[vertIndex] : pos.x;
  position.array[vertIndex + 1] = pos.y === undefined ? position.array[vertIndex + 1] : pos.y;
  position.array[vertIndex + 2] = pos.z === undefined ? position.array[vertIndex + 2] : pos.z;
};

export const updtatePtsWithCoord = (
  selectedPiece: any,
  oldPoint: Float32Array,
  newPoint: Float32Array,
) => {
  for (let i = 0; i < selectedPiece.geometry.attributes.position.array.length; i += 3) {
    const isGoodCoord = oldPoint?.every((point: any, index: number) => {
      return point === selectedPiece.geometry.attributes.position.array[i + index];
    });

    if (isGoodCoord) {
      const pos = {
        x: (selectedPiece.geometry.attributes.position.array[i] = newPoint[0]),
        z: (selectedPiece.geometry.attributes.position.array[i + 1] = newPoint[1]),
        y: (selectedPiece.geometry.attributes.position.array[i + 2] = newPoint[2]),
      };

      setVert(selectedPiece.geometry, i, pos);
    }
    const position = selectedPiece.geometry.getAttribute('position');
    position.needsUpdate = true;
  }
};

export const updtateGeometryWithCoord = (
  geometry: any,
  copyGeometry: any,
  oldPoint: Float32Array,
  newPoint: Float32Array,
) => {
  for (let i = 0; i < geometry.length; i += 3) {
    const isGoodCoord = oldPoint?.every((point: any, index: number) => {
      return point === geometry[i + index];
    });

    if (isGoodCoord) {
      copyGeometry[i] = newPoint[0];
      copyGeometry[i + 1] = newPoint[1];
      copyGeometry[i + 2] = newPoint[2];
    }
  }
};

export const getMovingAxisOf2Float32 = (a: Float32Array, b: Float32Array) => {
  const movingAxis: ('x' | 'z' | 'y')[] = [];
  if (a[0] !== b[0]) movingAxis.push('x');
  if (a[1] !== b[1]) movingAxis.push('z');
  if (a[2] !== b[2]) movingAxis.push('y');

  return movingAxis;
};

export const getMultiMovingAxisOf2Point = (
  a: {
    x: number;
    z: number;
    y: number;
  },
  b: {
    x: number;
    z: number;
    y: number;
  },
): ('x' | 'z' | 'y')[] => {
  const movingAxis: ('x' | 'z' | 'y')[] = [];

  if (a.x !== b.x) movingAxis.push('x');
  else if (a.z === b.z) {
    movingAxis.push('y');
  } else {
    movingAxis.push('z');
  }
  return movingAxis;
};

export const getMovingAxisOf2Point = (
  a: {
    x: number;
    z: number;
    y: number;
  },
  b: {
    x: number;
    z: number;
    y: number;
  },
): 'x' | 'z' | 'y' => {
  const margin = 0.02;
  if (Math.abs(a.x - b.x) > margin) return 'x';
  if (Math.abs(a.z - b.z) > margin) return 'z';
  return 'y';
};

export const getIndexWithAxis = (axis: string) => {
  if (axis === 'x') return 0;
  if (axis === 'z') return 1;
  return 2;
};

export const getAroundPoint = (number: number) => {
  return Number.parseFloat(number.toFixed(8));
};
export const getPointHistoryName = (point: Float32Array) => {
  return `${point[0]},${point[1]},${point[2]}`;
};

export const getReverseHistoryName = (history: any, point: Float32Array) => {
  for (const [key, value] of Object.entries(history)) {
    if (compare2Float32Array(value as Float32Array, point)) {
      return key;
    }
  }
  return getPointHistoryName(point);
};

export const isPointBigger = (
  a: {
    x: number;
    z: number;
    y: number;
  },
  b: {
    x: number;
    z: number;
    y: number;
  },
) => {
  return a.x + a.y + a.z < b.x + b.y + b.z;
};

export const getIndexWithAxe = (axe: 'x' | 'z' | 'y') => {
  if (axe === 'x') return 0;
  if (axe === 'y') return 1;
  return 2;
};

export const updateBoundingBox = (piece: any) => {
  const min = { x: 0, y: 0, z: 0 };
  const max = { x: 0, y: 0, z: 0 };

  const geometryArray = piece.geometry.attributes.position.array;
  for (let i = 0; i < geometryArray.length; i += 3) {
    if (geometryArray[i] > max.x) max.x = geometryArray[i];
    if (geometryArray[i] < min.x) min.x = geometryArray[i];

    if (geometryArray[i + 2] > max.z) max.z = geometryArray[i + 2];
    if (geometryArray[i + 2] < min.z) min.z = geometryArray[i + 2];

    if (geometryArray[i + 1] > max.y) max.y = geometryArray[i + 1];
    if (geometryArray[i + 1] < min.y) min.y = geometryArray[i + 1];
  }

  piece.geometry.boundingBox.min = new Vector3(min.x, min.y, min.z);
  piece.geometry.boundingBox.max = new Vector3(max.x, max.y, max.z);

  piece.geometry.boundingSphere.radius = piece.geometry.boundingBox.min.distanceTo(
    piece.geometry.boundingBox.max,
  );
};

export const movePieceWithPieceDeltaAndAxis = (
  piece: any,
  delta: {
    x: number;
    y: number;
    z: number;
  },
) => {
  piece?.position.set(
    piece?.position.x - delta.x,
    piece?.position.y - delta.y,
    piece?.position.z - delta.z,
  );
};

export const getPieceSize = (axe: 'x' | 'z' | 'y', geometry: Float32Array, round = true) => {
  let min = geometry[getIndexWithAxe(axe)];
  let max = geometry[getIndexWithAxe(axe)];

  for (let i = 0; i < geometry.length; i += 3) {
    if (min > geometry[i + getIndexWithAxe(axe)]) {
      min = geometry[i + getIndexWithAxe(axe)];
    }
    if (max < geometry[i + getIndexWithAxe(axe)]) {
      max = geometry[i + getIndexWithAxe(axe)];
    }
  }

  if (round) return String(Math.round((max - min) * 100));
  return String((max - min) * 100);
};

export const getAssemblyObject = (
  pieces: any[],
  customSteles: CustomSteleType[],
  customPlatings: any[],
  activePieces: any[],
  patterns: {
    [key: string]: {
      [key: string]: any;
    }[];
  },
) => {
  let assemblyBox = new THREE.Box3();

  let patternsDetails: any[] = [];
  // eslint-disable-next-line guard-for-in
  for (const key1 in patterns) {
    // eslint-disable-next-line guard-for-in, @typescript-eslint/no-for-in-array
    for (const key2 in patterns[key1]) {
      const { elements, texture, camera } = patterns[key1][key2];
      patternsDetails = [
        ...patternsDetails,
        ...elements.map((el: any) => {
          return { ...el, pieceUUID: key1, texture, camera };
        }),
      ];
    }
  }

  const mappedPatterns = patternsDetails.map((pattern) => {
    return {
      pieceUUID: pattern.pieceUUID,
      patternId: pattern.patternId,
      positionX: pattern.position.x,
      positionY: pattern.position.y,
      scaleX: pattern.scale?.x,
      scaleY: pattern.scale?.y,
      orientation: pattern.orientation,
      image: pattern.texture?.image?.toDataURL(),
      camera: JSON.stringify(pattern.camera.matrix.toArray()),
      type: pattern.type,
      color: pattern.color,
      fontFamily: pattern.fontFamily,
      fontSize: pattern.fontSize,
      letterSpacing: +pattern.letterSpacing,
      text: pattern.text,
      align: pattern.align,
      default: pattern.default,
      price: pattern.price,
      imageRatio: pattern.ratio,
    };
  });

  const groupAssemblies = activePieces.reduce((groups, item, index) => {
    const group = groups[item.underAssembly?.uuid] || [];

    if (item.underAssembly?.id !== undefined) {
      group.push({
        isBlocked: item.isBlocked,
        granit: item.granit,
        underAssemblyId: item.underAssembly?.id,
        positionId: item.position.id,
        pieceOnUnderAssemblyId: item.pieceOnUnderAssemblyId,
        ...pieces[index],
      });
      groups[item.underAssembly?.uuid] = group;
    }
    return groups;
  }, {});

  const groupsAssembliesObject = Object.values(groupAssemblies);

  const underAssemblies = groupsAssembliesObject.map((el: any) => {
    return {
      id: el[0].underAssemblyId,
      pieces: el.map((element: any) => {
        const geometryBox = new THREE.Box3();
        geometryBox.setFromArray(element.geometry.attributes.position.array);
        geometryBox.translate(element.position);
        assemblyBox = assemblyBox.union(geometryBox);

        return {
          isBlocked: element.isBlocked,
          id: element.id,
          newGeometry: [...element.geometry.attributes.position.array],
          position: element.position,
          positionId: element.positionId,
          pieceOnUnderAssemblyId: element.pieceOnUnderAssemblyId,
          granit: element.granit,
          patterns: mappedPatterns.filter((pattern) => pattern.pieceUUID === element.uuid),
        };
      }) as {
        isBlocked: boolean;
        id: number;
        newGeometry: number[];
        position: Position;
        positionId: number;
        pieceOnUnderAssemblyId: number;
        granit: { id: string };
      }[],
    };
  });

  const mapedPieces: any[] = [];

  for (const [index, piece] of pieces.entries()) {
    if (activePieces[index]?.underAssembly === undefined) {
      const geometryBox = new THREE.Box3();
      geometryBox.setFromArray(piece.geometry.attributes.position.array);
      geometryBox.translate(piece.position);
      assemblyBox = assemblyBox.union(geometryBox);

      mapedPieces.push({
        id: piece.id,
        newGeometry: [...piece.geometry.attributes.position.array],
        position: piece.position,
        granit: activePieces[index].granit,
        isBlocked: activePieces[index].isBlocked,
        patterns: mappedPatterns.filter((el) => el.pieceUUID === piece.uuid),
      });
    }
  }

  for (const el of customSteles) {
    const extrudeGeometry = new THREE.ExtrudeGeometry(el.shape, el.extrudeSettings);
    const geometryBox = new THREE.Box3();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    geometryBox.setFromArray(extrudeGeometry.attributes.position.array);
    geometryBox.translate(el.position);
    assemblyBox = assemblyBox.union(geometryBox);
  }

  const boxSize = new Vector3();
  assemblyBox.getSize(boxSize);

  const mappedCustomSteles = customSteles.map((el) => {
    return { ...el, patterns: mappedPatterns.filter((element) => el.id === element.pieceUUID) };
  });

  const mappedCustomPlatings = customPlatings.map((el) => {
    return { ...el, ...el.shapeDetails, key: el.key };
  });

  return {
    width: boxSize.x,
    depth: boxSize.z,
    pieces: mapedPieces,
    underAssemblies,
    steles: mappedCustomSteles,
    platings: mappedCustomPlatings,
  };
};

export const isAccesory = (key: string) => {
  const piecesType = ['STELE', 'TOMB', 'FOUNDATION', 'PLATING', 'BOX', 'SIDEWALKS', 'SOLE', 'ANY'];

  if (piecesType.includes(key)) return false;
  return true;
};

export const getPiece = (
  pieces: any[],
  customSteles: any[],
  customPlatings: any[],
  index: number,
) => {
  if (index > pieces.length - 1 && index < pieces.length + customSteles.length) {
    const stele = customSteles[index - pieces.length];

    const extrudeGeometry = new THREE.ExtrudeGeometry(stele.shape, stele.extrudeSettings);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return { ...stele, geometry: extrudeGeometry, uuid: stele.id };
  }
  if (index > pieces.length + customSteles.length - 1) {
    const plating = customPlatings[index - (pieces.length + customSteles.length)];

    const extrudeGeometry = new THREE.ExtrudeGeometry(plating.shape, {
      curveSegments: 12,
      steps: 2,
      depth: plating.shapeDetails.depth,
      bevelEnabled: false,
    });
    extrudeGeometry.rotateY(plating.shapeDetails.isRotated ? 1.57 : 0);
    return { ...plating, geometry: extrudeGeometry, uuid: plating.id };
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return pieces[index];
};

export const getQuoteLine = (
  assemblyDetails: {
    underAssemblies: {
      pieces: PieceDetail[];
      underAssemblyName: string;
      underAssemblyUUID: string;
      x: number;
      y: number;
      z: number;
      size: number;
    }[];
    pieces: PieceDetail[];
    steles: PieceDetail[];
    platings: PieceDetail[];
    accesories: PieceDetail[];
    patterns: {
      ttcPrice: number;
      htPrice: number;
      name: string;
      description: string;
      quantity: number;
      extra: any[];
      leaderGranitEngraving: boolean;
    }[];
    engravings: any[];
  },
  discount?: number,
) => {
  const quoteLines: {
    name: string;
    extra: string[];
    priceTTC: number;
    priceHT: number;
    purchasePrice: number;
    weight?: number;
    quantity: number;
    granit?: string;
    madeByLeaderGranit?: boolean;
    type: string;
  }[] = [];

  const totalPieces =
    assemblyDetails.pieces.length +
    assemblyDetails.accesories.length +
    assemblyDetails.steles.length +
    assemblyDetails.platings.length +
    assemblyDetails.underAssemblies.length;

  let totalTTCForEachPieces = 0;
  let deltaPrice = 0;
  if (discount && discount > 0) {
    const priceToAddToEachPiece = new Decimal(discount).dividedBy(totalPieces).toDecimalPlaces(2);

    deltaPrice = new Decimal(discount).minus(priceToAddToEachPiece.times(totalPieces)).toNumber();

    totalTTCForEachPieces = priceToAddToEachPiece.toNumber();
  }

  for (const [index, el] of assemblyDetails.pieces.entries()) {
    const isSole = el.type === 'SOLE';
    const delta = index === 0 ? deltaPrice : 0;
    if (index === 0) deltaPrice = 0; // apply delta price on one piece only

    const size = {
      x: Number.parseFloat(getPieceSize('x', el.geometry.attributes.position.array)),
      y: Number.parseFloat(getPieceSize('y', el.geometry.attributes.position.array)),
      z: Number.parseFloat(getPieceSize('z', el.geometry.attributes.position.array)),
    };
    quoteLines.push({
      name: `${el.name} ${size.x.toFixed(1)}cm x ${size.z.toFixed(1)}cm x ${size.y.toFixed(
        1,
      )}cm ht`,
      extra: [],
      priceTTC: el.ttcPrice + totalTTCForEachPieces + delta,
      priceHT: el.htPrice,
      purchasePrice: el.purchasePrice,
      quantity: 1,
      granit: el.granit?.name,
      weight: Math.round((el.granit?.weight ?? 0) * el.size),
      madeByLeaderGranit: true,
      type: isSole ? 'sole' : 'monument',
    });
  }
  for (const [index, el] of assemblyDetails.accesories.entries()) {
    const delta = index === 0 ? deltaPrice : 0;
    if (index === 0) deltaPrice = 0; // apply delta price on one piece only

    quoteLines.push({
      name: `${el.name} ${(el.x * 100).toFixed(1)}cm x ${(el.z * 100).toFixed(1)}cm x ${(
        el.y * 100
      ).toFixed(1)}cm ht`,
      extra: [],
      priceTTC: el.ttcPrice + totalTTCForEachPieces + delta,
      priceHT: el.htPrice,
      purchasePrice: el.purchasePrice,
      quantity: 1,
      granit: el.granit?.name,
      weight: Math.round((el.granit?.weight ?? 0) * el.size),
      madeByLeaderGranit: true,
      type: 'accesory',
    });
  }
  for (const [index, el] of assemblyDetails.steles.entries()) {
    const delta = index === 0 ? deltaPrice : 0;
    if (index === 0) deltaPrice = 0; // apply delta price on one piece only

    quoteLines.push({
      name: `${el.name} ${(el.x * 100).toFixed(1)}cm x ${(el.z * 100).toFixed(1)}cm x ${(
        el.y * 100
      ).toFixed(1)}cm ht`,
      extra: [],
      priceTTC: el.ttcPrice + totalTTCForEachPieces + delta,
      priceHT: el.htPrice,
      purchasePrice: el.purchasePrice,
      quantity: 1,
      granit: el.granit?.name,
      weight: Math.round((el.granit?.weight ?? 0) * el.size),
      madeByLeaderGranit: true,
      type: 'monument',
    });
  }
  for (const [index, el] of assemblyDetails.platings.entries()) {
    const delta = index === 0 ? deltaPrice : 0;
    if (index === 0) deltaPrice = 0; // apply delta price on one piece only

    quoteLines.push({
      name: `${el.name} ${(el.x * 100).toFixed(1)}cm x ${(el.z * 100).toFixed(1)}cm x ${(
        el.y * 100
      ).toFixed(1)}cm ht`,
      extra: [],
      priceTTC: el.ttcPrice + totalTTCForEachPieces + delta,
      priceHT: el.htPrice,
      purchasePrice: el.purchasePrice,
      quantity: 1,
      granit: el.granit?.name,
      weight: Math.round((el.granit?.weight ?? 0) * el.size),
      madeByLeaderGranit: true,
      type: 'monument',
    });
  }
  for (const [index, el] of assemblyDetails.underAssemblies.entries()) {
    const isSole = el.pieces.every((piece) => piece.type === 'SOLE');

    const delta = index === 0 ? deltaPrice : 0;
    if (index === 0) deltaPrice = 0; // apply delta price on one piece only

    quoteLines.push({
      name: '',
      extra: el.pieces.map(
        (element) =>
          `${element.name} ${(element.x * 100).toFixed(1)} x ${(element.z * 100).toFixed(1)} x ${(
            element.y * 100
          ).toFixed(1)} ht`,
      ),
      priceTTC:
        el.pieces.reduce((sum, piece) => sum + piece.ttcPrice, 0) + totalTTCForEachPieces + delta,
      priceHT: el.pieces.reduce((sum, piece) => sum + piece.htPrice, 0),
      purchasePrice: el.pieces.reduce((sum, piece) => sum + piece.purchasePrice, 0),
      quantity: 1,
      granit: el.pieces.map((element) => element.granit?.name).join('/n'),
      weight: Math.round(
        el.pieces.reduce((sum, piece) => sum + (piece.granit?.weight ?? 0) * piece.size, 0),
      ),
      madeByLeaderGranit: true,
      type: isSole ? 'sole' : 'monument',
    });
  }
  for (const [index, el] of assemblyDetails.patterns.entries()) {
    quoteLines.push({
      name: `${el.name} ${el.description ?? ''}`,
      extra: [],
      priceTTC: el.ttcPrice,
      priceHT: el.htPrice,
      purchasePrice: 0,
      quantity: 1,
      weight: 0,
      madeByLeaderGranit:
        typeof el.leaderGranitEngraving === 'boolean' ? el.leaderGranitEngraving : true,
      type: 'pattern',
    });
  }

  const traductAlign = (align: 'left' | 'right' | 'center') => {
    switch (align) {
      case 'right': {
        return 'droite';
      }
      case 'center': {
        return 'centre';
      }
      default: {
        return 'gauche';
      }
    }
  };
  for (const el of assemblyDetails.engravings) {
    quoteLines.push({
      name: `${el.text} | ${el.fontSize}cm | ${getColorName(el.color)} | ${getFontName(
        el.fontFamily,
      )} | espacement: ${el.letterSpacing}cm | aligné: ${traductAlign(el.align)}`,
      extra: [],
      priceTTC: el.ttcPrice,
      priceHT: el.htPrice,
      purchasePrice: 0,
      quantity: 1,
      madeByLeaderGranit: true,
      weight: 0,
      type: 'engraving',
    });
  }
  return quoteLines;
};
