/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useRef, useState } from 'react';
import { Text } from 'react-konva';
import { Vector3 } from 'three';
import * as THREE from 'three';

type KanvasEngravingProps = {
  pattern: {
    inverse_horizontal: boolean;
    inverse_vertical: boolean;
    position: Vector3;
    pieceUUID: string;
    orientation: string;
    uuid: string;
    text: string;
    fontSize: string;
    letterSpacing: string;
    fontFamily: string;
    align: string;
    color: string;
  };
  stageRef: React.MutableRefObject<any>;
  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };
  setPatterns: (patterns: { [key: string]: { [key: string]: any }[] }) => void;
  patterns: {
    [key: string]: { [key: string]: any }[];
  };
  pieceUUID: string;
  setSelectedPattern: (selectedPattern: number | undefined) => void;
  patternIndex: number;
  orbitRef: React.MutableRefObject<any>;
  ratio: number | undefined;
  kanvasSize: { width: number; height: number } | undefined;
};

export const KanvasEngraving = ({
  pattern,
  orientation,
  setPatterns,
  patterns,
  pieceUUID,
  stageRef,
  setSelectedPattern,
  patternIndex,
  orbitRef,
  ratio,
  kanvasSize,
}: KanvasEngravingProps) => {
  const shapeRef = useRef<any>();

  const changePattern = (position?: Vector3) => {
    const patternsTmp = { ...patterns };
    const currentOrientation = `${orientation.key}${orientation.inverse ? '_inverse' : ''}`;

    patternsTmp[pattern.pieceUUID][currentOrientation].elements = patterns[pattern.pieceUUID][
      currentOrientation
    ].elements.map((element: any) => {
      if (element.uuid === pattern.uuid) {
        return {
          ...element,
          position: position || element.position,
        };
      }
      return element;
    });

    if (stageRef.current && patternsTmp[pieceUUID][currentOrientation]) {
      const canvas = stageRef.current.toCanvas();
      const texture = new THREE.CanvasTexture(canvas);
      patternsTmp[pieceUUID][currentOrientation].texture = texture;

      patternsTmp[pieceUUID][currentOrientation].camera = orbitRef.current.object.clone();
    }

    setPatterns(patternsTmp);
  };

  const handleDragEnd = (e: any) => {
    changePattern(new Vector3(Math.round(e.target.x()), Math.round(e.target.y()), 0));
  };

  const sizeWithRatio = (cm: number) => {
    return cm * (ratio ?? 1) * 1.4;
  };

  const fontSize = sizeWithRatio(
    Number.isNaN(Number.parseInt(pattern.fontSize, 10))
      ? 0.3
      : Number.parseInt(pattern.fontSize, 10),
  );

  const letterSpacing = sizeWithRatio(
    Number.isNaN(Number.parseInt(pattern.letterSpacing, 10))
      ? 0
      : Number.parseInt(pattern.letterSpacing, 10),
  );

  return (
    <Text
      onClick={() => setSelectedPattern(patternIndex)}
      onTap={() => setSelectedPattern(patternIndex)}
      ref={shapeRef}
      x={pattern.position.x}
      y={pattern.position.y}
      fontSize={fontSize}
      letterSpacing={letterSpacing}
      fontFamily={pattern.fontFamily}
      align={pattern.align}
      fill={pattern.color}
      draggable
      onDragEnd={handleDragEnd}
      text={pattern.text}
      onMouseEnter={(e) => {
        const container = e.target?.getStage()?.container();
        if (container) container.style.cursor = 'pointer';
      }}
      onMouseLeave={(e) => {
        const container = e.target?.getStage()?.container();
        if (container) container.style.cursor = 'default';
      }}
    />
  );
};
