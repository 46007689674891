import { Check, Close, Delete, Edit, West } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  Input,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';

import { useUser } from 'src/context/UserProvider';
import { CenteredModal, ModalTitle } from '../../../../../components/CenteredModal';
import {
  FlexBox,
  FormLine,
  LowerCaseButton,
  RoundedButton,
} from '../../../../../components/common';
import { AutocompleteRHF } from '../../../../../components/forms/AutocompleteRhf';
import { CustomPhoneInput } from '../../../../../components/forms/PhoneInputRhf';
import { SelectRHF } from '../../../../../components/forms/SelectRhf';
import SwitchRHF from '../../../../../components/forms/SwitchRhf';
import { TextFieldRHF } from '../../../../../components/forms/TextFieldRhf';
import { useEnv } from '../../../../../context/EnvProvider';
import useToggle from '../../../../../hook/useToggle';
import {
  CornerBox,
  ImageContainer,
  Profile,
  ProfilePictureContainer,
  StyledEditIcon,
  uploadProfilePicture,
  Wrapper,
} from '../../../../mySpace/record/general/General';
import useGeneral from './useGeneral';

const TagsContainer = styled(Box)({
  maxHeight: 75,
  overflow: 'auto',
  width: '100%',
});

const ActionWrapper = styled(Box)({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingRight: '40px',
});

const General = () => {
  const {
    edit,
    toggleEdit,
    methods,
    onRefresh,
    submit,
    billingAddress,
    setBillingAddress,
    user,
    setEdit,
    handleActivateUser,
    handleDisableUser,
    catalogs,
    companyOptions,
    countOrders,
    countQuotes,
    totalIncome,
  } = useGeneral();

  const [openValidate, toggleOpenValidate] = useToggle();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = methods;

  const { buckets } = useEnv();
  const { user: connectedUser } = useUser();

  return (
    <Stack justifyContent="space-between" height="100%">
      {user?.status === 'PENDING' && (
        <Alert
          severity="warning"
          action={
            <ActionWrapper>
              <Button color="success" size="small" onClick={toggleOpenValidate}>
                <Check />
                Accepter
              </Button>
              <Button color="error" size="small" onClick={handleDisableUser}>
                <Close />
                Refuser
              </Button>
            </ActionWrapper>
          }
        >
          <AlertTitle>Compte à valider</AlertTitle>
          Ce compte n&apos;aura pas accès au configurateur avant d&apos;avoir été validé
        </Alert>
      )}
      {user?.status === 'DISABLED' && (
        <Alert
          severity="error"
          action={
            <ActionWrapper>
              <Button color="inherit" size="small" onClick={toggleOpenValidate}>
                <Check />
                Réactiver
              </Button>
            </ActionWrapper>
          }
        >
          <AlertTitle>Compte refusé</AlertTitle>
          Ce compte a été refusé et ne peut plus se connecter
        </Alert>
      )}
      <form onSubmit={handleSubmit(submit)} style={{ padding: '30px', position: 'relative' }}>
        <CornerBox>
          {edit && (
            <LowerCaseButton variant="contained" type="submit">
              Enregistrer les modifications
            </LowerCaseButton>
          )}
          {!edit && (
            <LowerCaseButton variant="contained" onClick={toggleEdit}>
              Modifier les Informations
            </LowerCaseButton>
          )}
        </CornerBox>
        <Typography variant="h5">INFORMATIONS GÉNÉRALES</Typography>
        <ProfilePictureContainer>
          <ImageContainer>
            <Profile
              src={`${buckets?.BUCKET_PROFILE_PICTURES ?? ''}/${user?.profilePicture ?? 'default'}`}
              alt="pp"
            />
          </ImageContainer>
          <StyledEditIcon component="label">
            <Input
              type="file"
              sx={{ display: 'none' }}
              inputProps={{
                multiple: false,
                accept: 'image',
              }}
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files) {
                  await uploadProfilePicture(e.target.files[0], user?.id, onRefresh);
                }
              }}
            />
            <Edit />
          </StyledEditIcon>
        </ProfilePictureContainer>
        <Wrapper>
          <FormLine>
            <FormControlLabel
              control={
                <SwitchRHF name="headOffice" control={control} switchProps={{ disabled: !edit }} />
              }
              label="Compte siège"
              disabled={!edit}
              sx={{
                border: '1px solid #F2F4F7',
                borderRadius: '10px',
                height: '56px',
                width: '100%',
                margin: 0,
              }}
            />

            <TextFieldRHF
              name="reason"
              control={control}
              TextFieldProps={{
                label: 'Raison sociale',
                error: Boolean(errors.reason),
                helperText: errors.reason?.message,
                disabled: !edit,
              }}
            />
            {!watch('headOffice') && (
              <TextFieldRHF
                name="name"
                control={control}
                TextFieldProps={{
                  label: "Nom de l'agence",
                  error: Boolean(errors.name),
                  helperText: errors.name?.message,
                  disabled: !edit,
                }}
              />
            )}
            <PhoneInput
              name="phone"
              control={control}
              inputComponent={CustomPhoneInput}
              label="Téléphone"
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
              disabled={!edit}
              defaultCountry="FR"
            />
            {watch('headOffice') && <div />}
          </FormLine>
          <FormLine>
            {connectedUser?.role === 'ADMIN' ? (
              <FormControlLabel
                control={
                  <SwitchRHF
                    name="isOnMaintenance"
                    control={control}
                    switchProps={{ disabled: !edit }}
                  />
                }
                label="Maintenance"
                disabled={!edit}
                sx={{
                  border: '1px solid #F2F4F7',
                  borderRadius: '10px',
                  height: '56px',
                  width: '100%',
                  margin: 0,
                }}
              />
            ) : null}

            <TextFieldRHF
              name="function"
              control={control}
              TextFieldProps={{
                label: 'Fonction',
                error: Boolean(errors.reason),
                helperText: errors.reason?.message,
                disabled: !edit,
              }}
            />
          </FormLine>
          <Typography variant="h6">Adresse de Livraison</Typography>
          <FormLine>
            <TextFieldRHF
              name="deliveryAddress.address"
              control={control}
              TextFieldProps={{
                label: 'Adresse',
                error: Boolean(errors.deliveryAddress?.address),
                helperText: errors.deliveryAddress?.address?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="deliveryAddress.addressDetails"
              control={control}
              TextFieldProps={{
                label: "Complément d'adresse",
                error: Boolean(errors.deliveryAddress?.addressDetails),
                helperText: errors.deliveryAddress?.addressDetails?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="deliveryAddress.zipCode"
              control={control}
              TextFieldProps={{
                label: 'Code Postal',
                error: Boolean(errors.deliveryAddress?.zipCode),
                helperText: errors.deliveryAddress?.zipCode?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="deliveryAddress.city"
              control={control}
              TextFieldProps={{
                label: 'Ville',
                error: Boolean(errors.deliveryAddress?.city),
                helperText: errors.deliveryAddress?.city?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="deliveryAddress.country"
              control={control}
              TextFieldProps={{
                label: 'Pays*',
                error: Boolean(errors.deliveryAddress?.country),
                helperText: errors.deliveryAddress?.country?.message,
                disabled: !edit,
              }}
            />
          </FormLine>
          {billingAddress ? (
            <>
              <Typography variant="h6">Adresse de Facturation</Typography>
              <FormLine>
                {edit && (
                  <IconButton
                    sx={{ position: 'absolute', left: '-45px', top: '7px' }}
                    onClick={() => setBillingAddress(false)}
                  >
                    <Delete color="error" />
                  </IconButton>
                )}

                <TextFieldRHF
                  name="billingAddress.address"
                  control={control}
                  TextFieldProps={{
                    label: 'Adresse',
                    error: Boolean(errors.billingAddress?.address),
                    helperText: errors.billingAddress?.address?.message,
                    disabled: !edit,
                  }}
                />
                <TextFieldRHF
                  name="billingAddress.addressDetails"
                  control={control}
                  TextFieldProps={{
                    label: "Complément d'adresse",
                    error: Boolean(errors.billingAddress?.addressDetails),
                    helperText: errors.billingAddress?.addressDetails?.message,
                    disabled: !edit,
                  }}
                />
                <TextFieldRHF
                  name="billingAddress.zipCode"
                  control={control}
                  TextFieldProps={{
                    label: 'Code Postal',
                    error: Boolean(errors.billingAddress?.zipCode),
                    helperText: errors.billingAddress?.zipCode?.message,
                    disabled: !edit,
                  }}
                />
                <TextFieldRHF
                  name="billingAddress.city"
                  control={control}
                  TextFieldProps={{
                    label: 'Ville',
                    error: Boolean(errors.billingAddress?.city),
                    helperText: errors.billingAddress?.city?.message,
                    disabled: !edit,
                  }}
                />
                <TextFieldRHF
                  name="billingAddress.country"
                  control={control}
                  TextFieldProps={{
                    label: 'Pays*',
                    error: Boolean(errors.billingAddress?.country),
                    helperText: errors.billingAddress?.country?.message,
                    disabled: !edit,
                  }}
                />
              </FormLine>
            </>
          ) : (
            <FlexBox sx={{ flexDirection: 'column', gap: '5px' }}>
              <RoundedButton
                variant="outlined"
                onClick={() => {
                  setBillingAddress(true);
                  setEdit(true);
                }}
              >
                <West color="primary" />
                Ajouter une adresse de facturation
              </RoundedButton>
              <Typography textAlign="right" variant="caption">
                Si différente de l’adresse de livraison
              </Typography>
            </FlexBox>
          )}
        </Wrapper>
        <Typography variant="h5">INFORMATIONS DE CONNEXION</Typography>
        <Wrapper>
          <FormLine>
            <TextFieldRHF
              name="lastName"
              control={control}
              TextFieldProps={{
                label: 'Nom',
                error: Boolean(errors.lastName),
                helperText: errors.lastName?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="firstName"
              control={control}
              TextFieldProps={{
                label: 'Prénom',
                error: Boolean(errors.firstName),
                helperText: errors.firstName?.message,
                disabled: !edit,
              }}
            />
            <TextFieldRHF
              name="email"
              control={control}
              TextFieldProps={{
                label: 'Email',
                error: Boolean(errors.email),
                helperText: errors.email?.message,
                disabled: !edit,
              }}
            />
          </FormLine>
        </Wrapper>
        <Typography variant="h5">AUTRES INFORMATIONS</Typography>
        <Wrapper>
          <FormLine>
            <SelectRHF
              control={control}
              name="leaderGranitCompanyId"
              options={companyOptions}
              TextFieldProps={{
                label: 'Société',
                error: Boolean(errors.leaderGranitCompanyId),
                helperText: errors.leaderGranitCompanyId?.message,
                disabled: !edit,
              }}
            />
            <AutocompleteRHF
              control={control}
              name="catalogIds"
              options={catalogs}
              AutocompleteProps={{
                multiple: true,
                freeSolo: false,
                getOptionLabel: (option) => option.name,
                isOptionEqualToValue: (option, value) => option.id === value.id,
                renderTags: (value, getTagProps) => (
                  <TagsContainer>
                    {value.map(({ name }, index) => (
                      <Chip variant="outlined" label={name} {...getTagProps({ index })} />
                    ))}
                  </TagsContainer>
                ),
              }}
              TextFieldProps={{
                label: 'Catalogue',
                error: Boolean(errors.catalogIds),
                helperText: errors.catalogIds?.message,
                disabled: !edit,
              }}
            />
          </FormLine>
        </Wrapper>
        <Typography variant="h5">STATISTIQUES DE L&apos;UTILISATEUR</Typography>
        <Wrapper>
          <FormLine>
            <TextField label="Nombre de devis" value={countQuotes} disabled />
            <TextField label="Nombre de commandes" value={countOrders} disabled />
            <TextField label="Total H.T" value={totalIncome} disabled />
          </FormLine>
        </Wrapper>
      </form>
      {user?.status === 'ACTIVATED' && (
        <Alert
          severity="success"
          action={
            <ActionWrapper>
              <Button color="inherit" size="small" onClick={handleDisableUser}>
                <Check />
                Désactiver
              </Button>
            </ActionWrapper>
          }
          sx={{ bottom: 0, position: 'relative' }}
        >
          <AlertTitle>Compte accepté</AlertTitle>
          Ce compte a été accepté et a accès à toutes les fonctionnalités de VisioGranits
        </Alert>
      )}

      <CenteredModal open={openValidate} handleClose={toggleOpenValidate}>
        <ModalTitle>
          <Typography variant="h5">Associer le compte a une société et un catalogue</Typography>
        </ModalTitle>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '50px 10%',
            gap: '30px',
          }}
        >
          <SelectRHF
            control={control}
            name="leaderGranitCompanyId"
            options={companyOptions}
            TextFieldProps={{
              label: 'Société',
            }}
          />
          <AutocompleteRHF
            control={control}
            name="catalogIds"
            options={catalogs}
            AutocompleteProps={{
              multiple: true,
              freeSolo: false,
              getOptionLabel: (option) => option.name,
              isOptionEqualToValue: (option, value) => option.id === value.id,
              renderTags: (value, getTagProps) => (
                <TagsContainer>
                  {value.map(({ name }, index) => (
                    <Chip variant="outlined" label={name} {...getTagProps({ index })} />
                  ))}
                </TagsContainer>
              ),
            }}
            TextFieldProps={{
              label: 'Catalogue',
            }}
          />
          <LowerCaseButton
            variant="contained"
            disabled={!watch('leaderGranitCompanyId') || watch('catalogIds').length === 0}
            onClick={() => {
              handleActivateUser();
              toggleOpenValidate();
            }}
          >
            Activer le compte
          </LowerCaseButton>
        </Box>
      </CenteredModal>
    </Stack>
  );
};

export default General;
