/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldErrors, SubmitHandler, UseFormHandleSubmit, UseFormReset } from 'react-hook-form';
import { ModalTitle } from 'src/components/CenteredModal';
import { FormLine, LowerCaseButton } from 'src/components/common';
import FileSettings from 'src/components/icons/FileSettingsIcon';
import useAdditionalServices from 'src/hook/useAdditionalServices';
import { ClientInformationsFormSchema } from 'src/hook/useConfiguratorNavigation';
import { AdditionalService } from 'src/services/additionalServices';
import { Assembly } from 'src/services/assemblies';
import { Quote, updateAdditionalServices } from 'src/services/quotes';
import muiTheme from 'src/themes/muiTheme';

interface IInstallations {
  quote?: Quote;
  closeForm: () => void;
  handleSubmit: UseFormHandleSubmit<ClientInformationsFormSchema>;
  errors: FieldErrors<ClientInformationsFormSchema>;
  reset: UseFormReset<ClientInformationsFormSchema>;
  userId: string;
  activeInstallations: { price: number; name: string; display: boolean }[];
  setActiveInstallations: (
    activeInstallations: { price: number; name: string; display: boolean }[],
  ) => void;
  assembly: Assembly | null;
  onRefresh: () => void;
}

export const Installations = ({
  quote,
  closeForm,
  handleSubmit,
  errors,
  reset,
  userId,
  activeInstallations,
  setActiveInstallations,
  assembly,
  onRefresh,
}: IInstallations) => {
  const user = quote?.user;

  const [installations, setInstallations] = useState(
    activeInstallations?.map((el) => ({
      price: String(el.price),
      name: el.name,
      display: el.display,
    })) ?? [],
  );

  const onSubmitForm: SubmitHandler<ClientInformationsFormSchema> = () => {
    setActiveInstallations(
      installations.map((el) => {
        const display =
          el.name === 'Pose'
            ? !user?.quoteSettings?.addInstallationToMonumentPrice
            : !user?.quoteSettings?.addInstallationToSolePrice;

        return {
          price: Number.parseFloat(el.price),
          name: el.name,
          display,
        };
      }),
    );
    closeForm();
  };

  const handleAddInstallation = () => {
    setInstallations([...installations, { price: '0', name: '', display: false }]);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmitForm)}>
      <ModalTitle sx={quote ? { background: '#F7F0E7' } : {}}>
        <Typography
          sx={{
            color: quote ? muiTheme.palette.secondary.main : muiTheme.palette.primary.main,
            width: '100%',
            paddingLeft: '30px',
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
          variant="h2"
          textAlign="left"
        >
          <FileSettings fontSize="large" />
          Forfait pose
        </Typography>
      </ModalTitle>
      <Body>
        {installations.map((el, elIndex) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <FormLine>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Sélectionner une pose</InputLabel>
                  <Select
                    label="Sélectionner une pose"
                    value={el.name}
                    onChange={(e) => {
                      setInstallations(
                        installations.map((element, elementIndex) => {
                          if (elIndex !== elementIndex) return element;
                          return { ...element, name: e.target.value };
                        }),
                      );
                    }}
                  >
                    <MenuItem value="Pose">Pose monument</MenuItem>
                    <MenuItem value="Pose de la semelle">Pose de la semelle</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <TextField
                    label="Prix"
                    value={el.price}
                    onChange={(e) => {
                      setInstallations(
                        installations.map((element, elementIndex) => {
                          if (elIndex !== elementIndex) return element;
                          return { ...element, price: e.target.value };
                        }),
                      );
                    }}
                  />
                </FormControl>
              </FormLine>
              <Image
                onClick={() => {
                  setInstallations(
                    installations.filter((_element, elementIndex) => {
                      if (elIndex === elementIndex) return false;
                      return true;
                    }),
                  );
                }}
                src="/icons/bin.svg"
                alt="bin"
                style={{
                  height: 18,
                  width: 20,
                  marginLeft: 12,
                  cursor: 'pointer',
                }}
              />
            </div>
          );
        })}

        <Button
          onClick={handleAddInstallation}
          variant="outlined"
          sx={{
            borderRadius: 50,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 'fit-content',
            gap: '20px',
            borderColor: '#BD9A67',
            color: '#000',
            textTransform: 'none',
          }}
        >
          <img src="/icons/plus.svg" alt="plus" />
          Ajouter une pose supplémentaire
        </Button>
        <FormLine>
          <div />
          <LowerCaseButton
            color={quote ? 'secondary' : 'primary'}
            onClick={() => {
              closeForm();
              reset();
            }}
          >
            Annuler
          </LowerCaseButton>
          <LowerCaseButton
            variant="contained"
            type="submit"
            color={quote ? 'secondary' : 'primary'}
          >
            Valider
          </LowerCaseButton>
        </FormLine>
      </Body>
    </StyledForm>
  );
};

const StyledForm = styled('form')(({ theme }) => ({
  background: 'white',
  boxShadow: theme.shadows[1],
  borderRadius: '10px',
}));

const Body = styled(Box)({
  padding: '30px',
  paddingRight: '50px',
  paddingLeft: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
});

const Image = styled('img')({
  width: '50px',
  height: '50px',
  borderRadius: 5,
  cursor: 'pointer',
});
