/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { zodResolver } from '@hookform/resolvers/zod';
import { Paper, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CustomSteleType } from 'src/components/3d/Stele';
import { useUser } from 'src/context/UserProvider';
import paths from 'src/navigation/paths';
import { ModalType } from 'src/Screens/Configurator';
import { AdditionalServices } from 'src/Screens/project/components/AdditionalServices';
import ClientInformationsForm from 'src/Screens/project/components/ClientInformationsForm';
import { QuoteStep } from 'src/Screens/project/components/NavBar';
import { Assembly, updateAssembly, updatePictureOfAssembly } from 'src/services/assemblies';
import { Buckets } from 'src/services/file';
import { orderQuotes, updateAssemblyQuote } from 'src/services/quotes';
import { emptyAddress, optionalSchemaAddress } from 'src/utils/address.utils';
import { handleMutation } from 'src/utils/api.utils';
import { getAssemblyObject, getQuoteLine } from 'src/utils/configurator.utils';
import { uploadImage } from 'src/utils/file.utils';
import * as THREE from 'three';
import { z } from 'zod';
import { Installations } from 'src/Screens/project/components/Installations';
import { UpdateQuoteImage } from 'src/Screens/project/components/UpdateQuoteImage';
import { PieceDetail } from './useConfigurator';

export type ClientInformationsFormSchema = z.infer<typeof clientInformationsFormSchema>;

const stepToNextStep: { [step: string]: QuoteStep } = {
  CUSTOMIZATION: 'ACCESSORIES',
  ACCESSORIES: 'PATTERNS',
  PATTERNS: 'QUOTE',
};

const LAST_STEP: QuoteStep = 'QUOTE';

export const clientInformationsFormSchema = z.object({
  clientFirstName: z.string(),
  clientLastName: z.string(),
  clientAddress: optionalSchemaAddress,
  cemetary: z.string(),
  concessionName: z.string(),
  concessionHeight: z.number(),
  concessionWidth: z.number(),
  useDimensions: z.boolean(),
  familyRef: z.string(),
});

export const useConfiguratorNavigation = (
  pieces: any[],
  customSteles: CustomSteleType[],
  activePieces: any[],
  customPlatings: any[],
  patterns: {
    [key: string]: {
      [key: string]: any;
    }[];
  },
  assemblyId: string | undefined,
  gl: THREE.WebGLRenderer | undefined,
  assembly: Assembly | null,
  onRefresh: () => void,
  setOpenedModal: (openedModal: ModalType | undefined) => void,
  getAssemblyDetails: () => {
    underAssemblies: {
      pieces: PieceDetail[];
      underAssemblyName: string;
      underAssemblyUUID: string;
      x: number;
      y: number;
      z: number;
      size: number;
    }[];
    pieces: PieceDetail[];
    steles: PieceDetail[];
    platings: PieceDetail[];
    accesories: PieceDetail[];
    patterns: {
      ttcPrice: number;
      htPrice: number;
      name: string;
      description: string;
      quantity: number;
      extra: any[];
      leaderGranitEngraving: boolean;
    }[];
    engravings: any[];
  },
  orbitRef: any,
  takePictures: () => Promise<string[]>,
) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm<ClientInformationsFormSchema>({
    defaultValues: {
      clientFirstName: assembly?.quote?.clientFirstName ?? '',
      clientLastName: assembly?.quote?.clientLastName ?? '',
      clientAddress: assembly?.quote?.clientAddress ?? emptyAddress,
      cemetary: assembly?.quote?.cemetary ?? '',
      concessionName: assembly?.quote?.concessionName ?? '',
      concessionHeight: assembly?.quote?.concessionHeight ?? 0,
      concessionWidth: assembly?.quote?.concessionWidth ?? 0,
      useDimensions: assembly?.quote?.useDimensions ?? false,
      familyRef: assembly?.quote?.familyRef ?? '',
    },
    mode: 'onSubmit',
    resolver: zodResolver(clientInformationsFormSchema),
  });

  const { user } = useUser();

  const [step, setStep] = useState<QuoteStep>('CUSTOMIZATION');
  const [selectedPage, setSelectedPage] = useState<string>('pieces');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeAdditionalServices, setActiveAdditionalServices] = useState<string[]>([]);
  const [activeInstallations, setActiveInstallations] = useState<
    {
      price: number;
      name: string;
      display: boolean;
    }[]
  >([]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const updateMonument = useCallback(async () => {
    const assemblyObject = getAssemblyObject(
      pieces,
      customSteles,
      customPlatings,
      activePieces,
      patterns,
    );

    try {
      await updateAssembly(assemblyObject, assemblyId === undefined ? '' : assemblyId);
    } catch {
      toast.error('Erreur pendant la mise à jour du document');
    }
  }, [pieces, customSteles, customPlatings, activePieces, patterns, assemblyId]);

  const stepToPrevStep: { [step: string]: QuoteStep } = Object.entries(stepToNextStep).reduce(
    (acc, [key, value]) => {
      acc[value] = key as QuoteStep;
      return acc;
    },
    {} as { [step: string]: QuoteStep },
  );

  const handleNextStep = useCallback(async () => {
    if (
      step === 'ACCESSORIES' &&
      customPlatings.length === 0 &&
      customSteles.length === 0 &&
      activePieces.length === 0
    ) {
      return;
    }
    if (step === 'CUSTOMIZATION' || step === 'ACCESSORIES') {
      let allPiecesHaveTexture = true;

      for (const el of activePieces) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (el.texture === null) allPiecesHaveTexture = false;
      }
      for (const el of customSteles) {
        if (el.texture === null) allPiecesHaveTexture = false;
      }
      for (const el of customPlatings) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (el.texture === null) allPiecesHaveTexture = false;
      }

      if (!allPiecesHaveTexture) {
        setSelectedPage('textures');
        // return;
      }
    }

    setIsLoading(true);

    if (assembly?.quote?.status === 'DRAFT') {
      await updateMonument();
    }
    setIsLoading(false);
    const nextStep = stepToNextStep[step] ?? LAST_STEP;
    setStep(nextStep);
  }, [step, customPlatings, customSteles, activePieces, assembly?.quote?.status, updateMonument]);

  const handlePrevStep = useCallback(() => {
    const prevStep = stepToPrevStep[step];
    if (prevStep) {
      setStep(prevStep);
    } else {
      navigate(
        pathname.replace(paths.home.project.configurator.root, paths.home.project.catalog.root),
      );
    }
  }, [navigate, pathname, step, stepToPrevStep]);

  const submit = useCallback(
    async ({
      detailed,
      discount,
      hasToBeOrdered,
      installations,
    }: {
      detailed?: boolean;
      discount?: number;
      hasToBeOrdered?: boolean;
      installations?: { price: number; name: string }[];
    }) => {
      setIsLoading(true);
      await updateMonument();
      gl?.domElement.toBlob(
        async (blob) => {
          if (blob) {
            const file = new File([blob], 'image.jpeg', { type: 'image/jpeg' });

            const pictureName = await uploadImage(file, Buckets.BUCKET_3D_PIECES);

            if (pictureName && assembly?.id) {
              await updatePictureOfAssembly(assembly.id, pictureName);
            }
          }
        },
        'image/jpeg',
        0.5,
      );

      const assemblyDetails = getAssemblyDetails();

      const quoteLines = getQuoteLine(assemblyDetails, discount);

      try {
        const [assemblyImage, ...images] = await takePictures();

        if (assemblyImage && assembly?.id) {
          await updatePictureOfAssembly(assembly.id, assemblyImage);
        }

        const imagesUrls = images.map(
          (image) => `https://3d-pieces.s3.eu-west-3.amazonaws.com/${image}`,
        );

        await handleMutation(
          (datas) =>
            updateAssemblyQuote({
              ...datas,
              step,
              status: 'QUOTE',
              oldAssemblyId: Number(assembly?.id),
              detailed,
              discount,
              ...getValues(),
              quoteLines,
              installations,
              quoteImages: imagesUrls,
            }),
          {},
          () => {
            onRefresh();
            toast.success(`Votre devis a bien été ${hasToBeOrdered ? 'commandé' : 'sauvegardé'}`);
          },
        );

        if (hasToBeOrdered) await orderQuotes([Number(assembly?.id)]);
      } catch {
        toast.error('Erreur pendant la mise à jour du document');
      }
      setIsLoading(false);
    },
    [
      assembly?.id,
      onRefresh,
      step,
      gl?.domElement,
      getValues,
      getAssemblyDetails,
      takePictures,
      updateMonument,
    ],
  );

  useEffect(() => {
    if (assembly?.quote) {
      setActiveAdditionalServices(
        assembly.quote.additionalServicesOnQuote
          ? assembly.quote.additionalServicesOnQuote.map((el) => el.additionalService.id)
          : [],
      );
    }
  }, [assembly?.quote]);

  const modalComponent = (modal: ModalType): JSX.Element => {
    switch (modal) {
      case 'editInformations': {
        return (
          <ModalContainer>
            <ClientInformationsForm
              closeForm={() => setOpenedModal(undefined)}
              control={control}
              quote={assembly?.quote}
              errors={errors}
              handleSubmit={handleSubmit}
              reset={reset}
            />
          </ModalContainer>
        );
      }
      case 'additionalServices': {
        return (
          <ModalContainer>
            {user ? (
              <AdditionalServices
                closeForm={() => setOpenedModal(undefined)}
                activeAdditionalServices={activeAdditionalServices}
                setActiveAdditionalServices={setActiveAdditionalServices}
                quote={assembly?.quote}
                errors={errors}
                handleSubmit={handleSubmit}
                reset={reset}
                userId={user.id}
                assembly={assembly}
                onRefresh={onRefresh}
              />
            ) : null}
          </ModalContainer>
        );
      }
      case 'installations': {
        return (
          <ModalContainer>
            {user ? (
              <Installations
                closeForm={() => setOpenedModal(undefined)}
                activeInstallations={activeInstallations}
                setActiveInstallations={setActiveInstallations}
                quote={assembly?.quote}
                errors={errors}
                handleSubmit={handleSubmit}
                reset={reset}
                userId={user.id}
                assembly={assembly}
                onRefresh={onRefresh}
              />
            ) : null}
          </ModalContainer>
        );
      }
      case 'quoteImage': {
        return (
          <ModalContainer>
            <UpdateQuoteImage
              closeForm={() => setOpenedModal(undefined)}
              quote={assembly?.quote}
              onRefresh={onRefresh}
            />
          </ModalContainer>
        );
      }
      default: {
        return <ModalContainer>{modal}</ModalContainer>;
      }
    }
  };

  return {
    handleNextStep,
    handlePrevStep,
    submit,
    modalComponent,
    step,
    selectedPage,
    setSelectedPage,
    setStep,
    isLoading,
    activeInstallations,
    setActiveInstallations,
  };
};

const ModalContainer = styled(Paper)({
  position: 'absolute',
  width: '90%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflowY: 'auto',
  maxHeight: '90%',
});
