/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ArrowBack } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { Environment, OrbitControls, useCubeTexture } from '@react-three/drei';
import { Canvas, useLoader, useThree } from '@react-three/fiber';
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Plating } from 'src/components/3d/Plating';
import { Stele } from 'src/components/3d/Stele';
import { useConfigurator } from 'src/hook/useConfigurator';
import { useConfiguratorNavigation } from 'src/hook/useConfiguratorNavigation';
import useLoadData from 'src/hook/useLoadData';
import { Assembly, getAssembly } from 'src/services/assemblies';
import { compare2Float32Array, getPiece } from 'src/utils/configurator.utils';
import * as THREE from 'three';
import { Vector3, WebGLRenderer } from 'three';

import { TexturesButtons } from 'src/components/TextureButtons';
import { uploadImage } from 'src/utils/file.utils';
import { Buckets } from 'src/services/file';
import { centerCameraOnPiece, updateOrbit } from 'src/utils/pattern';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { PriceRecap } from 'src/components/PriceRecap';
import { Modal } from '../components/3d/Fragments/Modal';
import { Interface } from '../components/3d/Interface/Index';
import { Piece as PieceComponent } from '../components/3d/Piece';
import { LowerCaseButton } from '../components/common';
import AdminNavBar from './admin/components/AdminNavBar';
import NavBar, { QuoteStep } from './project/components/NavBar';
import Recap from './project/Recap';

type AnglesProps = {
  piece: any;
  selectedPoints: { pieceIndex: number; point: Float32Array }[] | null;
  pieceIndex: number;
  selectPoints: (pts: Float32Array) => void;
};

type Options = { backgroundColor: string; light: number };

export type States = {
  options: Options;
  texture: boolean;
  points: boolean;
  allPieces: boolean;
  hasAdvancedMode: boolean;
  wired: boolean;
  advancedMove: boolean;
  modelId: number;
};

export type StateNames = keyof States;

export type ModalType = 'additionalServices' | 'editInformations' | 'quoteImage' | 'installations';

export const Angles = ({ piece, selectedPoints, selectPoints, pieceIndex }: AnglesProps) => {
  const [points, setPoints] = useState<Float32Array[] | null>([]);

  useEffect(() => {
    const pointsTmp: Float32Array[] | null = [];
    const pts = piece.geometry.attributes.position.array;

    for (let i = 0; i < pts.length; i += 3) {
      pointsTmp.push(new Float32Array([pts[i], pts[i + 1], pts[i + 2]]));
    }
    setPoints(pointsTmp);
  }, [piece]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {points?.map((point, index) => {
        const isSelected = selectedPoints
          ? // eslint-disable-next-line unicorn/prefer-array-some
            !!selectedPoints.find((el) => {
              return compare2Float32Array(el.point, point) && pieceIndex === el.pieceIndex;
            })
          : false;

        const colors = isSelected ? new Float32Array([1, 0, 0, 1]) : null;

        return (
          <points
            onClick={(e) => {
              if (e.distanceToRay && e.distanceToRay < 0.002) selectPoints(point);
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            position={piece.position}
          >
            <bufferGeometry attach="geometry">
              <bufferAttribute
                attach="attributes-position"
                count={point.length / 3}
                array={point}
                itemSize={3}
                usage={THREE.DynamicDrawUsage}
                needsUpdate={isSelected}
              />
              {colors ? (
                <bufferAttribute
                  attach="attributes-color"
                  count={colors.length / 3}
                  array={colors}
                  itemSize={3}
                  usage={THREE.DynamicDrawUsage}
                />
              ) : null}
            </bufferGeometry>
            <pointsMaterial
              attach="material"
              vertexColors
              size={8}
              sizeAttenuation={false}
              fog={false}
            />
          </points>
        );
      })}
    </>
  );
};

type MonumentType = {
  selectedPoints: { pieceIndex: number; point: Float32Array }[] | null;
  pieces: THREE.Object3D<THREE.Event>[];
  pieceIndex: number;
  reloadPiece: boolean;
  customSteles: any[];
  customPlatings: any[];
  orbitRef: React.MutableRefObject<any>;
  activePieces: any[];
  setSelectedPoints: (seletedPoints: { pieceIndex: number; point: Float32Array }[] | null) => void;
  setPieceIndex: (pieceIndex: number) => void;
  setReloadPiece: (reloadPiece: boolean) => void;
  changePosition: (newPosition: Vector3) => void;
  setCustomSteles: (customSteles: any[]) => void;
  setCustomPlatings: (customPlatings: any[]) => void;
  setValue: <T extends StateNames>(stateName: T, value: States[T]) => void;
  getValue: (stateName: StateNames) => States[StateNames];
  checkCollision: (
    pieceIndexToCheck: number,
    axe: 'x' | 'y' | 'z',
    forceBox1?: {
      checkOnlySelectedPiece?: boolean;
      box1Geometry?: any[];
      box1Position?: { x: number; y: number; z: number };
    },
  ) => void;
  setGl: (gl: WebGLRenderer) => void;
  step: QuoteStep;
  patterns: {
    [key: string]: { [key: string]: any }[];
  };
  selectedPattern: number | undefined;
  orientation: {
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  };
  centerCamera: () => void;
};

const Monument = ({
  selectedPoints,
  pieces,
  pieceIndex,
  reloadPiece,
  customSteles,
  customPlatings,
  orbitRef,
  activePieces,
  setSelectedPoints,
  setPieceIndex,
  setReloadPiece,
  changePosition,
  setCustomSteles,
  setCustomPlatings,
  setValue,
  getValue,
  checkCollision,
  setGl,
  step,
  selectedPattern,
  patterns,
  orientation,
  centerCamera,
}: MonumentType) => {
  const gl = useThree((state) => state.gl);

  useEffect(() => {
    setGl(gl);
  }, [setGl, gl]);

  useEffect(() => {
    let minPoint = new Vector3(0, 0, 0);
    let maxPoint = new Vector3(0, 0, 0);

    for (const el of pieces) {
      const elementGeometry = (el as any).geometry.attributes.position.array as number[];

      for (let i = 0; i < elementGeometry.length; i += 3) {
        const elementX = elementGeometry[i + 0] + el.position.x;
        const elementY = elementGeometry[i + 1] + el.position.y;
        const elementZ = elementGeometry[i + 2] + el.position.z;

        if (elementX < minPoint.x) minPoint = new Vector3(elementX, minPoint.y, minPoint.z);
        if (elementX > maxPoint.x) maxPoint = new Vector3(elementX, maxPoint.y, maxPoint.z);

        if (elementY < minPoint.y) minPoint = new Vector3(minPoint.x, elementY, minPoint.z);
        if (elementY > maxPoint.y) maxPoint = new Vector3(maxPoint.x, elementY, maxPoint.z);

        if (elementZ < minPoint.z) minPoint = new Vector3(minPoint.x, minPoint.y, elementZ);
        if (elementZ > maxPoint.z) maxPoint = new Vector3(maxPoint.x, maxPoint.y, elementZ);
      }
    }

    const piecesBox = new THREE.Box3();

    piecesBox.set(minPoint, maxPoint);
    const center = new Vector3();
    piecesBox.getCenter(center);

    if (orbitRef.current) orbitRef.current.target = new Vector3(center.x, 0, center.z);
  }, [orbitRef, pieces, reloadPiece]);

  useEffect(() => {
    setPieceIndex(pieceIndex);
  }, [pieceIndex, setPieceIndex]);

  if (reloadPiece) {
    setReloadPiece(false);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const enablePan = getValue('advancedMove') ?? false;

  return (
    <>
      <OrbitControls
        ref={orbitRef}
        minPolarAngle={0}
        maxPolarAngle={Math.PI}
        enablePan={enablePan as boolean}
        onClick={() => {}}
      />

      {pieces.map((el: any, elIndex: number) => {
        return (
          <PieceComponent
            // eslint-disable-next-line react/no-array-index-key
            key={elIndex}
            orbitRef={orbitRef}
            indexPiece3D={elIndex}
            pieceIndex={pieceIndex}
            setPieceIndex={setPieceIndex}
            piece={el}
            reloadPiece={reloadPiece}
            selectedPoints={selectedPoints}
            setSelectedPoints={setSelectedPoints}
            changePosition={changePosition}
            setValue={setValue}
            getValue={getValue}
            activePieces={activePieces}
            setReloadPiece={setReloadPiece}
            pieces={pieces}
            checkCollision={checkCollision}
            step={step}
            selectedPattern={selectedPattern}
            patterns={patterns}
            orientation={orientation}
          />
        );
      })}

      {customSteles.map((el, elIndex) => {
        return (
          <Stele
            key={`stele_${pieceIndex + elIndex}`}
            orbitRef={orbitRef}
            pieceIndex={pieces.length + elIndex}
            setPieceIndex={setPieceIndex}
            index={pieceIndex}
            customStele={el}
            setCustomSteles={setCustomSteles}
            customSteles={customSteles}
            setValue={setValue}
            getValue={getValue}
            step={step}
            pieces={pieces}
            patterns={patterns}
            orientation={orientation}
            customPlatings={customPlatings}
          />
        );
      })}

      {customPlatings.map((el, elIndex) => {
        if (!el.isActive) return null;
        return (
          <Plating
            key={`plating_${pieceIndex + elIndex}`}
            orbitRef={orbitRef}
            customPlating={el}
            pieceIndex={pieces.length + customSteles.length + elIndex}
            setPieceIndex={setPieceIndex}
            index={pieceIndex}
            setCustomPlatings={setCustomPlatings}
            customPlatings={customPlatings}
            getValue={getValue}
            step={step}
          />
        );
      })}
    </>
  );
};

const SceneWithClipping = ({ children }: { children: any }) => {
  const onCreated = React.useCallback((state: any) => {
    state.gl.localClippingEnabled = true;
  }, []);

  return (
    <Canvas
      id="canvas"
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
      }}
      camera={{
        position: [1.5, 1, 3.5],
        fov: 40,
      }}
      dpr={[1, 2]}
      gl={{
        preserveDrawingBuffer: true,
        localClippingEnabled: true,
      }}
      raycaster={{
        params: { Points: { threshold: 0.01 }, Line: { threshold: 0.01 } },
      }}
      onCreated={onCreated}
    >
      {children}
    </Canvas>
  );
};

type ConfiguratorInitProps = {
  assembly: Assembly | null;
  onRefresh: () => void;
  assemblyId: string | undefined;
};

const ConfiguratorInit = ({ assembly, onRefresh, assemblyId }: ConfiguratorInitProps) => {
  const [openedModal, setOpenedModal] = useState<ModalType>();
  const [modelId, setModelId] = useState<number>(assembly?.modelId ?? -1);
  const orbitRef = useRef<any>();

  const {
    selectedPoints,
    constraints,
    userModifications,
    history,
    pieces,
    pieceIndex,
    reloadPiece,
    geometry,
    activePieces,
    customSteles,
    customPlatings,
    granits,
    setSelectedPoints,
    changePoint,
    refreshConstraints,
    refreshModifications,
    setPieceIndex,
    setReloadPiece,
    changeGeometry,
    setActivePieces,
    changePosition,
    setCustomSteles,
    removeOnePiece,
    movePieceOnAxe,
    checkCollision,
    setCustomPlatings,
    findPointOnTheSameAxe,
    findPointAround,
    getAssemblyDetails,
    patterns,
    setPatterns,
    getAssemblySize,
  } = useConfigurator(assembly, orbitRef);

  const [gl, setGl] = useState<WebGLRenderer>();
  const [options, setOptions] = useState<Options>({
    backgroundColor: '#EAECF0',
    light: 0.5,
  });
  const [hasActiveTexture, setHasActiveTexture] = useState<boolean>(false);
  const [hasPoints, setHasPoints] = useState<boolean>(false);
  const [allPieces, setAllPieces] = useState<boolean>(true);
  const [hasAdvancedMode, setHasAdvancedMode] = useState<boolean>(false);
  const [hasWired, setHasWired] = useState<boolean>(false);
  const [hasAdvancedMove, setHasAdvancedMove] = useState<boolean>(false);
  const [selectedPattern, setSelectedPattern] = useState<number>();
  const [orientation, setOrientation] = useState<{
    key: 'x' | 'y' | 'z' | 'free';
    inverse: boolean;
  }>({ key: 'free', inverse: false });

  const setValue = <T extends StateNames>(stateName: T, value: States[T]) => {
    switch (stateName) {
      case 'options': {
        setOptions(value as Options);
        break;
      }
      case 'texture': {
        setHasActiveTexture(value as boolean);
        break;
      }
      case 'points': {
        setHasPoints(value as boolean);
        break;
      }
      case 'hasAdvancedMode': {
        setHasAdvancedMode(value as boolean);
        break;
      }
      case 'allPieces': {
        setAllPieces(value as boolean);
        break;
      }
      case 'wired': {
        setHasWired(value as boolean);
        break;
      }
      case 'modelId': {
        setModelId(value as number);
        break;
      }
      case 'advancedMove': {
        setHasAdvancedMove(value as boolean);
        break;
      }
      default:
    }
  };

  const getValue = <T extends StateNames>(stateName: T): States[T] => {
    switch (stateName) {
      case 'options': {
        return options as States[T];
      }
      case 'texture': {
        return hasActiveTexture as States[T];
      }
      case 'points': {
        return hasPoints as States[T];
      }
      case 'allPieces': {
        return allPieces as States[T];
      }
      case 'hasAdvancedMode': {
        return hasAdvancedMode as States[T];
      }
      case 'wired': {
        return hasWired as States[T];
      }
      case 'advancedMove': {
        return hasAdvancedMove as States[T];
      }
      case 'modelId': {
        return modelId as States[T];
      }
      default: {
        throw new Error('State not found');
      }
    }
  };

  const takePictures = async () => {
    setValue('texture', true);
    setPieceIndex(-1);
    centerCamera();
    const result = Object.entries(patterns).flatMap(([key, subkeys]) =>
      Object.entries(subkeys).map(([subkey, values]) => {
        let pieceI = null;

        for (const [index, piece] of pieces.entries()) {
          if (piece.uuid === key) pieceI = index;
        }
        if (pieceI === null) {
          for (const [index, piece] of customSteles.entries()) {
            if (piece.id === key) pieceI = pieces.length + index;
          }
        }
        if (pieceI === null) {
          for (const [index, piece] of customPlatings.entries()) {
            if (piece.id === key) pieceI = pieces.length + customSteles.length + index;
          }
        }
        return {
          key,
          subkey,
          pieceIndex: pieceI,
          ...values,
        };
      }),
    );
    const images: Promise<string>[] = [];

    async function processResult() {
      await centerAndCapture(0, Buckets.BUCKET_3D_PIECES);
      for (const [resIndex, res] of result.entries()) {
        if (res.pieceIndex !== null) {
          const piece = getPiece(pieces, customSteles, customPlatings, res.pieceIndex);
          updateOrbit(
            gl,
            orbitRef,
            res.pieceIndex,
            pieces,
            customSteles,
            customPlatings,
            { key: res.subkey as any, inverse: res.subkey.includes('inverse') },
            centerCamera,
            setValue,
            () => {
              if (res.pieceIndex !== null) centerCameraOnPiece(res.pieceIndex, piece, orbitRef);
            },
          );
        }

        // eslint-disable-next-line no-await-in-loop
        await delay(1000 * resIndex + 1); // Attente avant capture d'image

        // eslint-disable-next-line no-await-in-loop
        const imageUrl = captureAndUploadImage(Buckets.BUCKET_3D_PIECES);
        images.push(imageUrl);
      }

      // Captures et configurations supplémentaires
      await centerAndCapture(0, Buckets.BUCKET_3D_PIECES);
      await centerAndCapture(-45, Buckets.BUCKET_3D_PIECES, () => {
        setValue('texture', false);
      });
      await centerAndCapture(0, Buckets.BUCKET_3D_PIECES);
      await centerAndCapture(-45, Buckets.BUCKET_3D_PIECES, () => {
        setPieceIndex(-1);
        centerCamera();
      });

      return Promise.all(images);
    }

    // Fonction utilitaire pour capturer et uploader une image
    async function captureAndUploadImage(bucket: Buckets): Promise<string> {
      return new Promise((resolve) => {
        gl?.domElement.toBlob(
          async (blob: Blob | null) => {
            if (blob) {
              const file = new File([blob], 'image.jpeg', { type: 'image/jpeg' });
              const pictureName = await uploadImage(file, bucket);
              resolve(`${pictureName}`);
            } else {
              resolve('');
            }
          },
          'image/jpeg',
          0.5,
        );
      });
    }

    // Fonction pour centrer la caméra et capturer une image
    async function centerAndCapture(angle: number | null, bucket: Buckets, callback?: () => void) {
      if (angle !== null) centerCamera(angle);
      await delay(1000); // Attendre avant capture d'image
      const imageUrl = captureAndUploadImage(bucket);
      images.push(imageUrl);
      if (callback) callback();
    }

    // Fonction utilitaire pour gérer les délais
    function delay(ms: number) {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    return processResult();
  };

  const {
    handleNextStep,
    handlePrevStep,
    submit,
    modalComponent,
    step,
    selectedPage,
    setSelectedPage,
    setStep,
    isLoading,
    activeInstallations,
    setActiveInstallations,
  } = useConfiguratorNavigation(
    pieces,
    customSteles,
    activePieces,
    customPlatings,
    patterns,
    assemblyId,
    gl,
    assembly,
    onRefresh,
    setOpenedModal,
    getAssemblyDetails,
    orbitRef,
    takePictures,
  );

  const centerCamera = useCallback(
    (azimuthAngleDeg?: number, polarAngleDeg?: number) => {
      const array: number[] = [];

      for (const el of pieces) {
        const box1Geometry: number[] = el.geometry.attributes.position.array;
        const box1Postion = el.position;

        for (const [elementIndex, element] of box1Geometry.entries()) {
          if (elementIndex % 3 === 0) array.push(element + box1Postion.x);
          if (elementIndex % 3 === 1) array.push(element + box1Postion.y * 0.6);
          if (elementIndex % 3 === 2) array.push(element + box1Postion.z);
        }
      }

      const piecesBox = new THREE.Box3();
      piecesBox.setFromArray(array);

      const center = new Vector3();
      piecesBox.getCenter(center);

      const azimuthAngleRad = azimuthAngleDeg
        ? (azimuthAngleDeg * Math.PI) / 180
        : 0.404_891_786_285_083_4;
      const polarAngleRad = polarAngleDeg
        ? (polarAngleDeg * Math.PI) / 180
        : 1.313_982_409_374_089_5;

      if (orbitRef.current) {
        orbitRef.current.enableRotate = true;
        orbitRef.current.object.fov = 40;

        orbitRef.current.position0.set(1.5, 1, 3.5);
        orbitRef.current.reset();
        orbitRef.current.target = center;
        orbitRef.current.setAzimuthalAngle(azimuthAngleRad);
        orbitRef.current.setPolarAngle(polarAngleRad);
        orbitRef.current.object.zoom = 0.9;
        orbitRef.current.object.updateProjectionMatrix();
      }
    },
    [orbitRef, pieces],
  );

  useEffect(() => {
    if (!hasAdvancedMove) centerCamera();
  }, [hasAdvancedMove, centerCamera]);

  return (
    <Container>
      {assembly?.quote ? <NavBar currStep={step} setStep={setStep} /> : <AdminNavBar />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
          height: window.innerHeight - 71,
          overflow: 'hidden',
        }}
      >
        <TexturesButtons step={step} setValue={setValue} getValue={getValue} />
        {assembly && step !== 'QUOTE' ? (
          <PriceRecap
            assembly={assembly}
            setModal={setOpenedModal}
            submit={submit}
            getAssemblyDetails={getAssemblyDetails}
            getAssemblySize={getAssemblySize}
            isLoading={isLoading}
            activePieces={activePieces}
            platings={customPlatings}
            steles={customSteles}
            activeInstallations={activeInstallations}
            setActiveInstallations={setActiveInstallations}
          />
        ) : null}
        <CanvasWrapper step={step}>
          <Modal
            isOpen={!!openedModal}
            onClose={() => {
              setOpenedModal(undefined);
            }}
          >
            {openedModal && modalComponent(openedModal)}
          </Modal>
          {step === 'QUOTE' && (
            <>
              {isLoading ? (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#ffffff',
                    opacity: 0.8,
                    zIndex: 999,
                  }}
                >
                  <p style={{ opacity: 1 }}>Le configurateur génère les rendus.</p>
                  <p style={{ opacity: 1 }}>Veuillez patienter</p>
                </div>
              ) : (
                <StyledButton onClick={handlePrevStep}>
                  <ArrowBack />
                  Etape précédente
                </StyledButton>
              )}
            </>
          )}

          <SceneWithClipping>
            {step === 'QUOTE' ? <color attach="background" args={['#ffffff']} /> : null}
            <directionalLight
              position={[2.5, 8, 5]}
              intensity={20}
              castShadow
              shadow-mapSize-width={1024}
              shadow-mapSize-height={1024}
              shadow-camera-far={50}
              shadow-camera-left={-10}
              shadow-camera-right={10}
              shadow-camera-top={10}
              shadow-camera-bottom={-10}
            />
            <directionalLight
              position={[2.5, -80, 5]}
              intensity={10}
              castShadow
              shadow-mapSize-width={1024}
              shadow-mapSize-height={1024}
              shadow-camera-far={50}
              shadow-camera-left={-10}
              shadow-camera-right={10}
              shadow-camera-top={10}
              shadow-camera-bottom={-10}
            />
            <Environment files="/buildings/forest_slope_4k_14.hdr" />
            <Suspense fallback={null}>
              <Monument
                setGl={setGl}
                selectedPoints={selectedPoints}
                setSelectedPoints={setSelectedPoints}
                pieces={pieces}
                pieceIndex={pieceIndex}
                setPieceIndex={setPieceIndex}
                reloadPiece={reloadPiece}
                setReloadPiece={setReloadPiece}
                changePosition={changePosition}
                customSteles={customSteles}
                setCustomSteles={setCustomSteles}
                customPlatings={customPlatings}
                setCustomPlatings={setCustomPlatings}
                setValue={setValue}
                getValue={getValue}
                orbitRef={orbitRef}
                activePieces={activePieces}
                checkCollision={checkCollision}
                step={step}
                selectedPattern={selectedPattern}
                patterns={patterns}
                orientation={orientation}
                centerCamera={centerCamera}
              />
            </Suspense>
          </SceneWithClipping>
        </CanvasWrapper>
        <InterfaceWrapper step={step}>
          {step === 'QUOTE' && assembly ? (
            <Recap
              assembly={assembly}
              setModal={setOpenedModal}
              submit={submit}
              getAssemblyDetails={getAssemblyDetails}
              getAssemblySize={getAssemblySize}
              isLoading={isLoading}
              activePieces={activePieces}
              platings={customPlatings}
              steles={customSteles}
              activeInstallations={activeInstallations}
              setActiveInstallations={setActiveInstallations}
            />
          ) : (
            <Interface
              gl={gl}
              selectedPoints={selectedPoints}
              setSelectedPoints={setSelectedPoints}
              constraints={constraints}
              changePoint={changePoint}
              modifications={userModifications}
              history={history}
              refreshConstraints={refreshConstraints}
              refreshModifications={refreshModifications}
              pieceIndex={pieceIndex}
              setPieceIndex={setPieceIndex}
              changeGeometry={changeGeometry}
              geometry={geometry}
              setActivePieces={setActivePieces}
              pieces={pieces}
              activePieces={activePieces}
              customSteles={customSteles}
              setCustomSteles={setCustomSteles}
              checkCollision={checkCollision}
              assembly={assembly}
              setCustomPlatings={setCustomPlatings}
              customPlatings={customPlatings}
              removeOnePiece={removeOnePiece}
              setValue={setValue}
              getValue={getValue}
              findPointOnTheSameAxe={findPointOnTheSameAxe}
              findPointAround={findPointAround}
              movePieceOnAxe={movePieceOnAxe}
              orbitRef={orbitRef}
              handleNextStep={handleNextStep}
              handlePrevStep={handlePrevStep}
              step={step}
              reloadPiece={reloadPiece}
              quote={!!assembly?.quote}
              granits={granits}
              setReloadPiece={setReloadPiece}
              centerCamera={centerCamera}
              setPatterns={setPatterns}
              patterns={patterns}
              selectedPattern={selectedPattern}
              setSelectedPattern={setSelectedPattern}
              orientation={orientation}
              setOrientation={setOrientation}
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              isLoading={isLoading}
            />
          )}
        </InterfaceWrapper>
      </div>
    </Container>
  );
};

const Configurator = () => {
  const params = useParams<{ assemblyId: string }>();

  const { data: assembly, onRefresh } = useLoadData(() =>
    getAssembly(params.assemblyId ?? undefined),
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (params.assemblyId !== '-1' && assembly === null) return <></>;

  return (
    <ConfiguratorInit assembly={assembly} onRefresh={onRefresh} assemblyId={params.assemblyId} />
  );
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
});

const CanvasWrapper = styled(Box)(({ step }: { step: QuoteStep }) => ({
  height: '100%',
  width: step === 'QUOTE' ? '55vw' : '65vw',
  position: 'relative',
}));

const InterfaceWrapper = styled(Box)(({ step }: { step: QuoteStep }) => ({
  display: 'flex',
  position: 'relative',
  width: step === 'QUOTE' ? '45vw' : '35vw',
  boxShadow: ' -4px 0px 15px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#FFFFFF',
  height: '100%',
}));

const StyledButton = styled(LowerCaseButton)({
  position: 'absolute',
  left: 50,
  top: 20,
  zIndex: 2,
  background: 'white',
  color: 'black',
  display: 'flex',
  gap: '16px',
});

export default Configurator;
