/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Assembly } from 'src/services/assemblies';
import { StateNames, States } from 'src/Screens/Configurator';
import { getQuoteLine } from 'src/utils/configurator.utils';
import { useUser } from 'src/context/UserProvider';
import Decimal from 'decimal.js';
import useToggle from './useToggle';
import useHandleClickOutside from './useHandleClickOutside';
import { PieceDetail } from './useConfigurator';

const priceFormatter = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
});

interface IUseReacpPrice {
  assembly: Assembly;
  getAssemblyDetails: () => {
    pieces: PieceDetail[];
    accesories: PieceDetail[];
    steles: PieceDetail[];
    platings: PieceDetail[];
    patterns: {
      scale: {
        x: number;
        y: number;
      };
    }[];
    engravings: any[];
    underAssemblies: {
      pieces: PieceDetail[];
      underAssemblyName: string;
      underAssemblyUUID: string;
      x: number;
      y: number;
      z: number;
      size: number;
    }[];
  };
  getAssemblySize: (wihoutSole?: boolean) => { depth: number; width: number };
  activePieces: any[];
  platings: any[];
  steles: any[];
  activeInstallations: { price: number; name: string; display: boolean }[];
  setActiveInstallations: (
    activeInstallations: { price: number; name: string; display: boolean }[],
  ) => void;
}
export const useReacpPrice = ({
  assembly,
  getAssemblyDetails,
  getAssemblySize,
  activePieces,
  platings,
  steles,
  activeInstallations,
  setActiveInstallations,
}: IUseReacpPrice) => {
  // const { user } = useUser();

  const assemblyUser = assembly.quote?.user;

  const [assemblyDetails, setAssemblyDetails] = useState<{
    pieces: PieceDetail[];
    accesories: PieceDetail[];
    steles: PieceDetail[];
    platings: PieceDetail[];
    engravings: any[];
    underAssemblies: {
      pieces: PieceDetail[];
      underAssemblyName: string;
      underAssemblyUUID: string;
      x: number;
      y: number;
      z: number;
      size: number;
    }[];
    patterns: any[];
  }>();

  useEffect(() => {
    setAssemblyDetails(getAssemblyDetails());
  }, [getAssemblyDetails]);

  const simplesPieces = {
    pieces: assemblyDetails?.pieces,
    steles: assemblyDetails?.steles,
    platings: assemblyDetails?.platings,
  };

  const allPieces = useMemo(() => {
    const allPiecesTmp = [];
    for (const piece of assemblyDetails?.pieces ?? []) {
      allPiecesTmp.push(piece);
    }
    for (const accesory of assemblyDetails?.accesories ?? []) {
      allPiecesTmp.push(accesory);
    }
    for (const stele of assemblyDetails?.steles ?? []) {
      allPiecesTmp.push(stele);
    }
    for (const plating of assemblyDetails?.platings ?? []) {
      allPiecesTmp.push(plating);
    }
    for (const underAssembly of assemblyDetails?.underAssemblies ?? []) {
      for (const piece of underAssembly.pieces) {
        allPiecesTmp.push(piece);
      }
    }
    for (const engraving of assemblyDetails?.engravings ?? []) {
      allPiecesTmp.push(engraving);
    }
    for (const pattern of assemblyDetails?.patterns ?? []) {
      allPiecesTmp.push(pattern);
    }

    return allPiecesTmp;
  }, [assemblyDetails]);

  const [showEditActions, toggleShowEditActions] = useToggle();
  const actionsSelectionRef = useRef<HTMLDivElement>(null);
  const buttonActionsSelectionRef = useRef<HTMLButtonElement>(null);

  useHandleClickOutside(actionsSelectionRef, toggleShowEditActions, buttonActionsSelectionRef);

  const [showVisibilityActions, toggleShowVisibilityActions] = useToggle();
  const visibilityDialogRef = useRef<HTMLDivElement>(null);
  const buttonVisibilityDialogRef = useRef<HTMLButtonElement>(null);

  useHandleClickOutside(
    visibilityDialogRef,
    toggleShowVisibilityActions,
    buttonVisibilityDialogRef,
  );

  const [detailedQuote, setDetailedQuote] = useState(true);
  const [showPurchasePrice, setShowPurchasePrice] = useState(false);
  const [displayPurchasePrice, setDisplayPurchasePrice] = useState(false);

  useEffect(() => {
    if (typeof assemblyUser?.quoteSettings?.simplifiedQuote === 'boolean')
      setDetailedQuote(!assemblyUser?.quoteSettings?.simplifiedQuote);
  }, [assemblyUser]);

  const getFormatedPrice = (price: string) => {
    return Number.isNaN(Number.parseFloat(price)) ? 0 : Number.parseFloat(price);
  };
  const detailKeys = Object.keys(simplesPieces) as (keyof typeof simplesPieces)[];

  const getPurchasePrice = (details: PieceDetail[]) => {
    return details.reduce((sum, piece) => sum + piece.htPrice, 0);
  };

  const hasPieceOfTypeSole = useCallback(() => {
    return activePieces.some((obj) => obj && obj.type === 'SOLE');
  }, [activePieces]);

  const hasPiceOtherThanSole = useCallback(() => {
    if (steles && steles.length > 0) return true;
    if (platings && platings.length > 0) return true;

    return activePieces.some((obj) => obj && obj.type !== 'SOLE');
  }, [activePieces, platings, steles]);

  const soleInstallationPrice = useMemo(() => {
    let installation = 0;

    if (hasPieceOfTypeSole() && assemblyUser?.quoteSettings?.activeSoleInstallation) {
      installation = assemblyUser?.quoteSettings?.soleInstallation ?? 0;
    }
    return Number.parseFloat(String(installation));
  }, [hasPieceOfTypeSole, assemblyUser]);

  const installationPrice = useMemo(() => {
    let installation = 0;

    if (hasPiceOtherThanSole()) {
      const assemblySizeWithoutSole = getAssemblySize(true);

      if (
        assembly.type === 'COLUMBARIUM' &&
        assemblyUser?.quoteSettings?.activeSpecialInstallation
      ) {
        return Number.parseFloat(String(assemblyUser?.quoteSettings?.specialInstallation ?? 0));
      }
      if (
        assemblyUser?.quoteSettings?.activeCineraryInstallation &&
        assemblySizeWithoutSole.depth * assemblySizeWithoutSole.width <= 1
      ) {
        installation += assemblyUser.quoteSettings?.cineraryInstallation ?? 0;
      } else if (
        assemblyUser?.quoteSettings?.activeMonumentInstallation &&
        assemblySizeWithoutSole.depth * assemblySizeWithoutSole.width <= 2.76 &&
        assemblySizeWithoutSole.depth * assemblySizeWithoutSole.width >= 1
      ) {
        installation += assemblyUser.quoteSettings?.monumentInstallation ?? 0;
      } else if (
        assemblyUser?.quoteSettings?.activeSpecialInstallation &&
        assemblySizeWithoutSole.depth * assemblySizeWithoutSole.width >= 2.76
      ) {
        installation += assemblyUser.quoteSettings?.specialInstallation ?? 0;
      }
    }
    return Number.parseFloat(String(installation));
  }, [
    assembly.type,
    getAssemblySize,
    hasPiceOtherThanSole,
    assemblyUser?.quoteSettings?.activeCineraryInstallation,
    assemblyUser?.quoteSettings?.activeMonumentInstallation,
    assemblyUser?.quoteSettings?.activeSpecialInstallation,
    assemblyUser?.quoteSettings?.cineraryInstallation,
    assemblyUser?.quoteSettings?.monumentInstallation,
    assemblyUser?.quoteSettings?.specialInstallation,
  ]);

  useEffect(() => {
    const installations = [];

    if (assembly?.quote?.installations && assembly?.quote?.installations.length > 0) {
      setActiveInstallations(
        assembly?.quote?.installations.map((inst) => {
          const isMonument = inst.name === 'Pose';
          const display = isMonument
            ? !assemblyUser?.quoteSettings?.addInstallationToMonumentPrice
            : !assemblyUser?.quoteSettings?.addInstallationToSolePrice;
          return {
            ...inst,
            display,
          };
        }),
      );
    } else {
      if (installationPrice > 0) {
        installations.push({
          price: installationPrice,
          name: 'Pose',
          display: !assemblyUser?.quoteSettings?.addInstallationToMonumentPrice,
        });
      }
      if (soleInstallationPrice > 0) {
        installations.push({
          price: soleInstallationPrice,
          name: 'Pose de la semelle',
          display: !assemblyUser?.quoteSettings?.addInstallationToSolePrice,
        });
      }

      setActiveInstallations(installations);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    installationPrice,
    soleInstallationPrice,
    assemblyUser?.quoteSettings?.addInstallationToMonumentPrice,
    assemblyUser?.quoteSettings?.addInstallationToSolePrice,
  ]);

  const additionalServicesPrice = useMemo(() => {
    return (
      assembly.quote?.additionalServicesOnQuote?.reduce((sum, el) => {
        if (typeof el.additionalService.price === 'string')
          return sum + Number.parseFloat(el.additionalService.price);
        return sum + el.additionalService.price;
      }, 0) ?? 0
    );
  }, [assembly.quote?.additionalServicesOnQuote]);

  const getSalePrice = (details: PieceDetail[]) => {
    const piecesPrice = details.reduce((sum, piece) => sum + piece.ttcPrice, 0);
    const installationsPrices = activeInstallations.reduce((sum, obj) => sum + obj.price, 0);

    return piecesPrice + additionalServicesPrice + installationsPrices;
  };

  const totalWeight = useMemo(() => {
    if (assemblyDetails) {
      const quoteLines = getQuoteLine(assemblyDetails);

      return quoteLines.reduce((sum, obj) => sum + (obj.weight ?? 0) / 1000, 0);
    }
    return 0;
  }, [assemblyDetails]);

  const delivery = useMemo(() => {
    let deliveryPrice = 0;

    if (assemblyDetails && !assemblyUser?.quoteSettings?.postageDue) {
      if (showPurchasePrice) {
        if (!assemblyUser?.quoteSettings?.activeCustomDelivery && assemblyUser?.deliveryCost) {
          deliveryPrice = Math.ceil(
            Number.parseFloat(assemblyUser?.deliveryCost.price) * totalWeight,
          );
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        deliveryPrice =
          (assemblyUser?.quoteSettings?.activeCustomDelivery
            ? assemblyUser?.quoteSettings?.customDelivery ?? 0
            : Number.parseFloat(assemblyUser?.deliveryCost?.price ?? '0') *
              (assemblyUser?.userCoefficients?.delivery ?? 1)) * totalWeight;
      }
    }

    return deliveryPrice;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assemblyDetails, assemblyUser?.quoteSettings, showPurchasePrice, assemblyUser]);

  const totalSalePrice = useMemo(() => {
    const salePrice = getSalePrice(allPieces);
    const total = (salePrice + delivery).toFixed(2);

    return Number.parseFloat(total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPieces, delivery]);

  const totalPurchasePrice = useMemo(() => {
    const salePrice = getPurchasePrice(allPieces);

    return salePrice + delivery;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPieces, delivery]);

  const [finalPrice, setFinalPrice] = useState<string>('');
  const [discount, setDiscount] = useState<string>(String((assembly.quote?.discount ?? 0) * -1));
  const [percentage, setPercentage] = useState<string>();
  const [isEditingFinalPrice, setIsEditingFinalPrice] = useState<boolean>(false);
  const [isEditingDiscount, setIsEditingDiscount] = useState<boolean>(false);
  const [isEditingDiscountPercentage, setIsEditingDiscountPercentage] = useState<boolean>(false);
  const [openedPattern, setOpenedPattern] = useState<string>();

  const [openUnderAssemblies, setOpenUnderAssemblies] = useState<boolean[]>([]);

  useEffect(() => {
    if (assemblyDetails) {
      setOpenUnderAssemblies(assemblyDetails.underAssemblies.map(() => false));
    }
  }, [assemblyDetails]);

  useEffect(() => {
    if (showPurchasePrice) {
      setFinalPrice(totalPurchasePrice.toFixed(2));
    } else if (Number.parseFloat(discount ?? '')) {
      setFinalPrice((totalSalePrice - Number.parseFloat(discount ?? '')).toFixed(2));
    } else {
      setFinalPrice(totalSalePrice.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount, showPurchasePrice, totalPurchasePrice, totalSalePrice]);

  useEffect(() => {
    const discountTmp = Number.parseFloat(discount ?? '');
    if (discountTmp > 0) {
      setPercentage(((discountTmp / totalSalePrice) * 100).toFixed(2));
    }
  }, [discount, totalSalePrice]);

  const getMonumentPrice = () => {
    let monumentPieces = [];

    for (const piece of assemblyDetails?.pieces ?? []) {
      monumentPieces.push(piece);
    }
    for (const stele of assemblyDetails?.steles ?? []) {
      monumentPieces.push(stele);
    }
    for (const plating of assemblyDetails?.platings ?? []) {
      monumentPieces.push(plating);
    }
    for (const underAssembly of assemblyDetails?.underAssemblies ?? []) {
      for (const piece of underAssembly.pieces) {
        monumentPieces.push(piece);
      }
    }

    if (discount && Number.parseFloat(discount) < 0) {
      const numberOfPieces = monumentPieces.length;

      const priceToAddToEachPiece = new Decimal(discount)
        .dividedBy(numberOfPieces)
        .toDecimalPlaces(2);

      const deltaPrice = new Decimal(discount).minus(priceToAddToEachPiece.times(numberOfPieces));

      monumentPieces = monumentPieces.map((piece, pieceIndex) => {
        const delta = pieceIndex === 0 ? deltaPrice.toNumber() : new Decimal(0);
        const ttc = new Decimal(piece.ttcPrice);

        const result = ttc.plus(priceToAddToEachPiece.plus(delta).times(-1)).toNumber();

        return {
          ...piece,
          ttcPrice: result,
        };
      });
    }

    if (showPurchasePrice) return priceFormatter.format(getPurchasePrice(monumentPieces));

    let monumentSalePrice = monumentPieces.reduce((sum, piece) => sum + piece.ttcPrice, 0);

    if (!assemblyUser?.quoteSettings?.displayDelivery && assemblyDetails) {
      const quoteLines = getQuoteLine(assemblyDetails);

      const monumentWeight = quoteLines
        .filter((obj) => obj.type === 'monument')
        .reduce((sum, obj) => sum + (obj.weight ?? 0), 0);

      monumentSalePrice += delivery * (monumentWeight / (totalWeight * 1000));
    }

    if (
      assemblyUser?.quoteSettings?.activeMonumentInstallation &&
      assemblyUser?.quoteSettings?.addInstallationToMonumentPrice &&
      hasPiceOtherThanSole()
    ) {
      const monumentInstallationPrice = activeInstallations.find((el) => el.name === 'Pose')?.price;
      monumentSalePrice += monumentInstallationPrice ?? 0;
    }

    if (
      assemblyUser?.quoteSettings?.activeSoleInstallation &&
      assemblyUser?.quoteSettings?.addInstallationToSolePrice &&
      hasPieceOfTypeSole()
    ) {
      const monumentInstallationPrice = activeInstallations
        .filter((el) => el.name === 'Pose de la semelle')
        .reduce((sum, el) => sum + (el.price ?? 0), 0);
      monumentSalePrice += monumentInstallationPrice ?? 0;
    }

    return priceFormatter.format(monumentSalePrice);
  };

  const getAccessoriesPrice = () => {
    const accessories = [];
    for (const piece of assemblyDetails?.accesories ?? []) {
      accessories.push(piece);
    }

    if (showPurchasePrice) return priceFormatter.format(getPurchasePrice(accessories));

    let monumentSalePrice = accessories.reduce((sum, piece) => sum + piece.ttcPrice, 0);

    if (!assemblyUser?.quoteSettings?.displayDelivery && assemblyDetails) {
      const quoteLines = getQuoteLine(assemblyDetails);

      const monumentWeight = quoteLines
        .filter((obj) => obj.type === 'accesory')
        .reduce((sum, obj) => sum + (obj.weight ?? 0), 0);

      monumentSalePrice += delivery * (monumentWeight / (totalWeight * 1000));
    }

    return priceFormatter.format(monumentSalePrice);
  };

  return {
    simplesPieces,
    assemblyDetails,
    showPurchasePrice,
    detailedQuote,
    priceFormatter,
    additionalServicesPrice,
    delivery,
    toggleShowEditActions,
    buttonActionsSelectionRef,
    showEditActions,
    actionsSelectionRef,
    setDiscount,
    toggleShowVisibilityActions,
    buttonVisibilityDialogRef,
    showVisibilityActions,
    visibilityDialogRef,
    setDetailedQuote,
    displayPurchasePrice,
    setIsEditingFinalPrice,
    setShowPurchasePrice,
    setDisplayPurchasePrice,
    discount,
    isEditingDiscount,
    setIsEditingDiscount,
    isEditingDiscountPercentage,
    percentage,
    setPercentage,
    setIsEditingDiscountPercentage,
    totalSalePrice,
    isEditingFinalPrice,
    setFinalPrice,
    finalPrice,
    getFormatedPrice,
    totalPurchasePrice,
    getMonumentPrice,
    setOpenUnderAssemblies,
    openUnderAssemblies,
    detailKeys,
    getAccessoriesPrice,
    setOpenedPattern,
    openedPattern,
  };
};
